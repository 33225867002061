// export const apiUrls = {

  
//   UPLOADFILE: "/uploadFile",
//   GETFILE:"/getDeck",
//   CONVERT:"/converttoimage",
//   REGISTER:"/register",
//   GETPPTDATA:"/pptData",
//   GETSUMMARY:"/getSummary",
//   GETSLIDESTEXT:"/getSlidesText",
//   CREATESLIDETEXT:"/createSummary",
//   GETSLIDESID:"/getSlideId"
  
// };
export const apiUrls = {

  
  UPLOADFILE: "/uploadFile",
  GETFILE:"/deck/listAll",
  CONVERT:"/deck/upload",
  REGISTER:"/register",
  GLOGIN:"/glogin",
  LOGIN:"/login",
  COGNITOREGISTER:"/cognitoRegister",
  COGNITOLOGIN:"/cognitoLogin",
  COGNITOVERIFY:"/verifyLogin",
  COGNITOEMAILVERIFY:"/verifyEmail",
  COGNITORESENDCODE:"/resendConfirmationCode",
  PREACESS:"/preaccess",
  GETSLIDESID:"/getSlideId",
  GETACCOUNTDATA:"/account/getAccountData",
  CHECKUNQNAME:"/account/checkUniqueName",
  SUBMITFEEDBACK:"/account/submitFeedback",
  UPDATEACCOUNT:"/account/updateAccountData",
  UPDATEADDRESS:"/account/updateAddress",
  GETADDRESS:"/account/getAddress",
  GETPPTDATA:"/deck/dataById",
  GETPPTDATABYDECKID:"/deck/dataByDeckId",
  GETSUMMARY:"/deck/getSummary",
  GETSLIDESTEXT:"/deck/slideDataById",
  CREATESLIDETEXT:"/deck/createSummary",
  RENAMEDECK:"/deck/renameById",
  DELETEDECK:"/deck/deleteById",
  GENAUDIO:"/deck/audioGeneration",
  SLIDEDOWNLOAD:"/deck/downloadSlide",
  UPDATELOCATION:"/deck/updateLocation",
  CREATEAISUMMARY:"/deck/createAISummary",
  MEDIAUPLOAD:"/deck/mediaUpload",
  VIDDOWNLOAD:"/deck/vidDownload",
  DELETEMEDIA:"/deck/deleteMedia",
  UPDATEPPTDATA:"/deck/updateDeckData",
  DELETESLIDE:"/deck/deleteSlide",
  RENEWSLIDE:"/deck/renewSlide",
  UPDATESLIDESEQ:"/deck/updateSlideSequence",
  GETPRESENTERS:"/presenter/getPresenters",
  SAMPLEUPLOAD:"/presenter/sampleupload", 
  RENAMEPRESENTER:"/presenter/renameById",
  DELETEPRESENTER:"/presenter/deleteById",
  TRACKDATA:"/track/addData",
  GETUSERPLANDATA:"/track/getUserPlanData",
  GETANALYTICSDATA:"/track/getAnalyticsData",
  CREATEPAYMETINTENT:"/payment/createPaymentIntent",
  UPDATEPAYMETINTENT:"/payment/updatePaymentStatus",
  GETPAYMENTPLANS:"/payment/getPaymentPlans",
  GETUSERPLAN:"/payment/getUserPlan",
  CREATESUBSCRIPTION:"/payment/createSubscription"
  
};