import React, { useState } from 'react';



const LandingModal = ({ isLandingModalOpen, onCloseLandingModal, isEmailExist, isMobile}) => {
  const removeModal = () =>{
      onCloseLandingModal();
   }
 
    return (
    <div class="popup_box" id="myModal" style={{display:isLandingModalOpen?"flex":"none"}}>
   <div class="model_box black">
      <div class="model_box_child thanksPop" id="thanksPop"  style={isMobile ? {} : { width: "545px" }}>
         <div class="contentSection">
            <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128" fill="none">
              <path d="M40.0001 64L56.0001 80L88.0001 48M117.333 64C117.333 93.4552 93.4553 117.333 64.0001 117.333C34.5449 117.333 10.6667 93.4552 10.6667 64C10.6667 34.5448 34.5449 10.6667 64.0001 10.6667C93.4553 10.6667 117.333 34.5448 117.333 64Z" stroke="#D9D9D9" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <h3 class="mt-16">Thanks!</h3>
            {isEmailExist ? (
            <p class="mt-8">Your email address is already in our records. You will hear from us once Deck is ready. Until then follow along our journey on <a href='https://www.linkedin.com/company/deck-work' style={{textDecoration:"underline"}}>LinkedIn</a>, <a href="https://twitter.com/deckdotwork" style={{textDecoration:"underline"}}>X</a> and <a href="https://www.instagram.com/deck.wrk" style={{textDecoration:"underline"}}>Instagram</a>.</p>
            ) : (
               <p class="mt-8">We have registered your interest in Deck. You will hear from us once Deck is ready. Until then follow along our journey on <a href='https://www.linkedin.com/company/deck-work' style={{textDecoration:"underline"}}>LinkedIn</a>, <a href="https://twitter.com/deckdotwork" style={{textDecoration:"underline"}}>X</a> and <a href="https://www.instagram.com/deck.wrk" style={{textDecoration:"underline"}}>Instagram</a>.</p>
            )}   
            <a class="btn Primary mt-16" href="javascript:void(0);" onClick={removeModal} >Go back</a>
         </div>
      </div>
   </div>
</div>


);
};

export default LandingModal;