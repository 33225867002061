import React from 'react';

const Loading = ({ closeModal,activeNavItem, editAction }) => {
  // Simulating a loading process
 // setTimeout(() => {
   // closeModal(); // Close modal after 3 seconds (you can adjust this)
  //}, 3000);
  let label1= '';
  let label2= '';
if(editAction === 'imageUpload') {
   label1 = "Uploading your media File"
   label2 =  ""
} else {  
 label1 = activeNavItem === "Presenters"?"Creating your voice sample":"Importing presentation..."
 label2 = activeNavItem === "Presenters"?"This window will auto-close once the file has finished uploading":"This window will auto-close once presentation has finished importing"
}
  return (
    <div className="loading-component">
      {/* You can add more loading-related content here */}
      <div class="model_box_child importingPPPop" id="importingPPPop" style={{ width: '752px',  /* Any other styles */ }}>
            <div class="contentSection">
            <div class="loaderIcon">
               {/* <svg xmlns="http://www.w3.org/2000/svg" width="80" height="81" viewBox="0 0 80 81" fill="none">
                 <path d="M40 8V16.3333M40 60.5V73.8333M19.1667 40.5H7.5M70.8333 40.5H65.8333M61.5237 62.0237L59.1667 59.6667M62.214 18.5526L57.5 23.2667M16.4052 64.0948L25.8333 54.6667M17.0956 17.8623L24.1667 24.9333" stroke="#181110" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
               </svg> */}
               <img src="../assets/images/loading-7528_128.gif" />
            </div>
            <p class="mt-24">{label1}</p>
            <span class="mt-4">{label2}</span>
         </div>
      </div>
    </div>
 
  );
};

export default Loading;
