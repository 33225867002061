import React, { useState, useEffect } from "react";
import FileUpload from './FileUpload';
import Loading from './Loading';
import SuccessMessage from './SuccessMessage'; 
import PresenterModalContent from './PresenterModalContent'; 
import ChildModal from './ChildModal'; 
import SyncModal from './SyncModal'; 

const Modal = ({ closeModal, activeNavItem, onFileUploadComplete, onItemClick, editFileId, editTitle, editAction, setActiveNavItem, itemId, editSlideIndexNo, collectEmail, userDetail , onCompleteAction, showAlert}) => {
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSucess] = useState(true);
  const [uploadedFileId, setUploadedFileId] = useState("");
  const [uploadedDeckId, setUploadedDeckId] = useState("");
  const [uploadedTitle, setUploadedTitle] = useState("");
console.log("editActionModal", editAction, itemId)
  const handleFileUpload = () => {
    setIsLoading(true);

      // Simulating file upload
      // Replace this with your actual file upload logic
     // await uploadFile(); // Call your file upload function
      setIsFileUploaded(true); // Set state to indicate file upload completion
  

  };

  const handleComplete = (fileId,title, error = false, deckId = false) => {
    console.log("filleeeeeeeeeeeess",fileId,deckId)
    if (error === false ) {
    if(editAction === 'imageUpload') {
      setUploadedTitle(editTitle)
      setUploadedFileId(itemId)
      //onItemClick(itemId, editTitle); 
    } else {
      setUploadedTitle(title)
      setUploadedFileId(fileId)
      console.log("dddeckId",deckId)
      if(deckId) {
        setUploadedDeckId(deckId)
      }
    }
    setIsSucess(true)
  } else {
    console.log("titledddddddddd", title)
    setUploadedTitle(title)
    setIsSucess(false)
  }
    setIsLoading(false);
    onFileUploadComplete();


  
    // Handle completion logic after file upload
  }

  

  return (
    <div className="popup_box open" id="myModal" style={{display: "flex"}}>
      {/* Modal content */}
         <div className={`model_box  ${(editAction === 'showAlert' || editAction === "showRestrict" || editAction === 'rename' || editAction === 'delete') ? 'gray':''}`} >
         { editAction === 'rename' || editAction === 'delete'  || editAction === 'feedback'|| editAction === 'customprompt' || editAction === "showAlert" || editAction === "showRestrict" ? (
          <ChildModal closeModal={closeModal} editFileId={editFileId} editTitle={editTitle} editAction={editAction} activeNavItem={activeNavItem} handleFileUpload={handleFileUpload} collectEmail={collectEmail} userDetail={userDetail} setActiveNavItem={setActiveNavItem} onCompleteAction={onCompleteAction} showAlert={showAlert}/>
         ) : editAction === 'sync' ? (
            <SyncModal  closeModal={closeModal} mainfileId={itemId} title={editTitle} onCompleteAction={onCompleteAction} />
        ) : (
        <>
         {!isFileUploaded ? (
           activeNavItem === 'Presenters' ? ( // Render different content for presenter modal
           <PresenterModalContent onFileUpload={handleFileUpload} onComplete={handleComplete} closeModal={closeModal} activeNavItem={activeNavItem} />
           ) : (
            <FileUpload onFileUpload={handleFileUpload} onComplete={handleComplete} closeModal={closeModal} activeNavItem={activeNavItem} editAction={editAction} editFileId={editFileId} editTitle={editTitle}/>
           )
        ) : isLoading ? (
          <Loading closeModal={closeModal}  activeNavItem={activeNavItem} editAction={editAction}/>
        ) : (
          <SuccessMessage closeModal={closeModal} activeNavItem={activeNavItem} onItemClick={onItemClick} uploadedFileId={uploadedFileId} uploadedTitle={uploadedTitle} setActiveNavItem={setActiveNavItem} editAction={editAction} editSlideeIndexNo={editSlideIndexNo} isSuccess={isSuccess} uploadedDeckId={uploadedDeckId}/> // Render success message component
        )}
        </>
        )}
      </div>
    </div>
  );
};

export default Modal;
