import React, { useState, useEffect } from 'react';
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import { isValidEmail, utilFn } from "../utils/utilFn";
import LandingModal from './LandingModal';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from "@react-oauth/google";

import  CustomGoogleButton  from '../components/CustomGoogleButton';




const Landing = ({ handleLogin }) => {
    const [isLandingModalOpen, setIsLandingModalOpen] = useState(false);
    const [isEmailExist, setIsEmailExist] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isButtonDisabled, setButtonDisabled] = useState(false);
 
    const submitAccessReq = async (e) => {
      e.preventDefault();
      try { 
         if (!emailAddress) {
          setErrorMessage('Please enter valid email address.');
         } else {
            const isEmailValid = isValidEmail(emailAddress);
            if (isEmailValid) {
               setButtonDisabled(true);
               setErrorMessage('');
               const formData = new FormData();
               formData.append("emailId", emailAddress);
               const headers={
                  "Content-Type": "application/json",
               }
               const response = await callAPI(apiUrls.PREACESS, {}, "POST",formData,{},headers);
               console.log(response);
               if(response.data.userId){
                  setIsLandingModalOpen(true)
                  setButtonDisabled(false);
               } else if(response.data.existUserId) {
                  setIsEmailExist(true)
                  setIsLandingModalOpen(true)
                  setButtonDisabled(false);
               }
            } else {
               setButtonDisabled(false);
               setErrorMessage('Email address is not valid');
            }

         } 
      } catch (error) {
          console.error('An error occurred:', error);
      }
    };

   const onCloseLandingModal = () => {
      setEmailAddress("")
      setIsEmailExist(false)
      setIsLandingModalOpen(false)
    }

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        // Check if the window width is less than or equal to a certain value (e.g., 767 pixels)
        const mobileThreshold = 767;
        setIsMobile(window.innerWidth <= mobileThreshold);
      };
  
      // Initial check on component mount
      handleResize();
  
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
  
      // Cleanup on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
 

  return (
    <>
{isLandingModalOpen && <LandingModal isLandingModalOpen={isLandingModalOpen} onCloseLandingModal={onCloseLandingModal} isEmailExist={isEmailExist} isMobile={isMobile}/>}
<div class="container">
   <div class="wrapper">
      <div class="homeSection">
         <div class="headDeck">
            <div class="logo">
               <div class="logoImg">
                  <svg xmlns="http://www.w3.org/2000/svg" width="261" height="88" viewBox="0 0 261 88" fill="none">
                    <path d="M37.6749 85.7884H0V0H37.6749C43.0938 0.0776014 47.6029 0.698413 51.2023 1.86243C57.3331 3.84127 62.2971 7.46914 66.0943 12.746C69.1399 17.0141 71.2165 21.6314 72.324 26.5979C73.4315 31.5644 73.9852 36.2981 73.9852 40.7989C73.9852 52.2063 71.6516 61.8677 66.9842 69.7831C60.6556 80.4533 50.8859 85.7884 37.6749 85.7884ZM51.2616 21.8836C48.4533 17.2275 42.896 14.8995 34.5897 14.8995H17.7398V70.8889H34.5897C43.2124 70.8889 49.2246 66.7178 52.6262 58.3757C54.4852 53.7972 55.4148 48.3457 55.4148 42.0212C55.4148 33.291 54.0304 26.5785 51.2616 21.8836Z" fill="#D9D9D9"/>
                    <path d="M135.117 67.164C134.682 70.9277 132.685 74.7496 129.125 78.6296C123.587 84.7989 115.835 87.8836 105.867 87.8836C97.6399 87.8836 90.3818 85.284 84.0928 80.0847C77.8037 74.8854 74.6592 66.4268 74.6592 54.709C74.6592 43.7284 77.4873 35.3086 83.1435 29.4497C88.8392 23.5908 96.216 20.6614 105.274 20.6614C110.653 20.6614 115.498 21.6508 119.81 23.6296C124.121 25.6085 127.681 28.7319 130.489 33C133.021 36.7637 134.662 41.1287 135.414 46.0952C135.849 49.0053 136.027 53.1958 135.948 58.6667H91.6871C91.9244 65.03 93.9614 69.4921 97.7982 72.0529C100.132 73.6437 102.94 74.4392 106.223 74.4392C109.704 74.4392 112.532 73.4691 114.707 71.5291C115.894 70.4815 116.942 69.0265 117.852 67.164H135.117ZM118.386 47.7249C118.109 43.3404 116.744 40.0229 114.292 37.7725C111.879 35.4832 108.873 34.3386 105.274 34.3386C101.358 34.3386 98.3124 35.5414 96.1369 37.9471C94.001 40.3527 92.6562 43.612 92.1024 47.7249H118.386Z" fill="#D9D9D9"/>
                    <path d="M196.664 45.1058H179.34C179.023 42.739 178.212 40.6049 176.907 38.7037C175.009 36.1429 172.062 34.8624 168.067 34.8624C162.371 34.8624 158.475 37.6367 156.379 43.1852C155.271 46.134 154.718 50.0529 154.718 54.9418C154.718 59.5979 155.271 63.3422 156.379 66.1746C158.396 71.4515 162.193 74.0899 167.77 74.0899C171.726 74.0899 174.534 73.0423 176.195 70.9471C177.856 68.8519 178.865 66.1358 179.221 62.7989H196.486C196.091 67.843 194.232 72.6155 190.909 77.1164C185.609 84.3721 177.758 88 167.355 88C156.952 88 149.299 84.9735 144.394 78.9206C139.489 72.8677 137.037 65.0106 137.037 55.3492C137.037 44.4462 139.746 35.9683 145.165 29.9153C150.584 23.8624 158.06 20.836 167.592 20.836C175.701 20.836 182.326 22.6208 187.468 26.1905C192.65 29.7601 195.715 36.0653 196.664 45.1058Z" fill="#D9D9D9"/>
                    <path d="M261 85.7884H240.59L225.105 58.6667L218.104 65.8254V85.7884H201.491V0.291005H218.104V46.5026L239.166 22.6402H260.11L237.505 46.9101L261 85.7884Z" fill="#D9D9D9"/>
                  </svg>
               </div>
               <div class="betaTag">
                  BETA
               </div>
            </div>
            <div class="logoTxt">
               <p class="mt-16">Presentations Powered by You and AI.</p>
            </div>
         </div>
         <div class="vidBox deckVideo" style={isMobile ? {} : { width: "711px", height: "410px" , border:"none", borderRadius:0}}>
         <iframe src="https://player.vimeo.com/video/884395322?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1"
         //  width="1920" height="1080"
            style={isMobile ? { width: '100%', height: '100%' } : { width: "100%", height: "100%" }}
           frameborder="0" 
           allow="autoplay; fullscreen; picture-in-picture"
            title="Deck Product_Shorter"></iframe> 
         </div>
         <div class="deckAccessLog">
            <p>We’re slowly rolling out access to everyone. Enter your email to be one of the first ones to try Deck</p>
            <div class="dForm mt-12">
            <form onSubmit={submitAccessReq} className="emailPasswordForm">
               {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
               <div class="inlineFlex gap16">
                  <input type="email" name=""  value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} placeholder="Enter your email address" style={{width: "320px"}} />
                  <button class="btn Primary light open_model" data-target="thanksPop" type="submit"  disabled={isButtonDisabled}>Request Access</button>
               </div>
               </form>
            </div>
         </div>
    
      </div>
      <div class="loginSection" style={{ maxWidth: '400px'}}>
      <GoogleOAuthProvider clientId="1039487477793-8iv47jsvilbe8ug57q9503o79u55a4pv.apps.googleusercontent.com"  scope="https://www.googleapis.com/auth/presentations">
              <CustomGoogleButton handleLogin={handleLogin} />
            </GoogleOAuthProvider>
      </div>   
   </div>
</div>
</>
  );
};

export default Landing;
