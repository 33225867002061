import React, { useState, useRef , useEffect} from "react";
import { Link } from 'react-router-dom';

const DeckContentItem = ({ title, imageUrl, fileId , onImageClick, isVisible, onToggle, openModal, deckId, onItemAnaly, prevIndexNo}) => {
 //   console.log("imageurl",imageUrl);
  //  console.log("title",title);
  const handleImageClick = () => {
   // Call the onImageClick function passed from CentreContent with the fileId
   onImageClick(fileId,title, 0,  deckId);
 };
 
 
 const copyLink = (path) => {
   const baseUrl = window.location.origin; // or window.location.protocol + '//' + window.location.host;
   const fullUrl = `${baseUrl}${path}`;
 
   navigator.clipboard.writeText(fullUrl)
     .then(() => {
       console.log('Link copied to clipboard:', fullUrl);
       // Provide user feedback or perform any additional actions
     })
     .catch((err) => {
       console.error('Error copying link to clipboard:', err);
       // Handle the error or provide user feedback
     });
 };
 const ulRef = useRef(null);

 const toggleUlVisibility = () => {
   // const moreOptionNav = moreOptionNavRef.current;
   //  const ulElement = ulRef.current;

   //  // Check if there's enough space to the right of the moreOptionNav
   //  const rightSpace = window.innerWidth - moreOptionNav.getBoundingClientRect().right;
    
   //  // If there's not enough space, position the ulElement to the left of moreOptionNav
   //  if (rightSpace < ulElement.offsetWidth) {
   //      ulElement.style.right = 'auto';
   //      ulElement.style.left = '0';
   //  } else {
   //      // Position the ulElement to the right of moreOptionNav
   //      ulElement.style.left = 'auto';
   //      ulElement.style.right = '0';
   //  }
   
   onToggle(fileId);
 };
 const moreOptionNavRef = useRef(null);
 

//  useEffect(() => {
//    const handleClickOutside = (event) => {
//      // Check if moreOptionNavRef is defined and if the event target is not within the moreOptionNavRef
//      if (moreOptionNavRef.current && !moreOptionNavRef.current.contains(event.target)) {
//        onToggle(null); // Set the fileId to null to hide all ul elements
//      }
//    };

//    document.addEventListener('mousedown', handleClickOutside);

//    return () => {
//      document.removeEventListener('mousedown', handleClickOutside);
//    };
//  }, [onToggle]);




 
 

 const handleRenClick = (action) => {
   // Call the callback to open the modal in App.js
   console.log("Innnnn")
   openModal(fileId,title, action);
 };
 
  return (
    <div class="itemWrap">
    <div class="item">
    
       <div class="thumb" onClick={handleImageClick}>
          <img src={imageUrl} alt="" />
       </div>
      
       <div class="titleInfo">
          <span>{title}</span>
          
          <div class="moreOptionNav" ref={moreOptionNavRef} onClick={toggleUlVisibility}>
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
             </svg>
             <ul ref={ulRef}  style={{ display: isVisible ? 'block' : 'none' , right:"0", left:"auto"}}>
                           <li onClick={() => handleRenClick('rename')}>
                              <a href="javascript:void(0);" class="open_modelgry" data-target="dpRenamePop">
                                 <em>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                       <path d="M7.99998 13.3333H14M2 13.3333H3.11636C3.44248 13.3333 3.60554 13.3333 3.75899 13.2965C3.89504 13.2638 4.0251 13.21 4.1444 13.1368C4.27895 13.0544 4.39425 12.9391 4.62486 12.7085L13 4.33333C13.5523 3.78104 13.5523 2.88561 13 2.33333C12.4477 1.78104 11.5523 1.78104 11 2.33333L2.62484 10.7085C2.39424 10.9391 2.27894 11.0544 2.19648 11.1889C2.12338 11.3082 2.0695 11.4383 2.03684 11.5744C2 11.7278 2 11.8909 2 12.217V13.3333Z" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                 </em>
                                 <em>Rename</em>
                              </a>
                           </li>
                           {deckId && (
                           <li onClick={() => copyLink(`/sharedPresentation?deckId=${deckId}`)}>
                              <a href="javascript:void(0);" class="open_modelgry" data-target="dpDeletePop">
                                 <em>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                       <path d="M8.47154 12.2426L7.52874 13.1854C6.22699 14.4872 4.11644 14.4872 2.81469 13.1854C1.51294 11.8837 1.51294 9.77313 2.81469 8.47138L3.7575 7.52857M12.2428 8.47138L13.1856 7.52857C14.4873 6.22682 14.4873 4.11627 13.1856 2.81453C11.8838 1.51278 9.77329 1.51278 8.47154 2.81453L7.52874 3.75733M5.66681 10.3333L10.3335 5.66663" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                 </svg>
                                 </em>
                                 <em>Copy Link</em>
                              </a>
                           </li>
                           )}
                                             <li onClick={() => onItemAnaly(fileId, title, imageUrl)}>
                              <a href="javascript:void(0);" class="open_modelgry" data-target="dpDeletePop">
                                 <em>
                                 <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 5H2.6C2.03995 5 1.75992 5 1.54601 5.10899C1.35785 5.20487 1.20487 5.35785 1.10899 5.54601C1 5.75992 1 6.03995 1 6.6V17.4C1 17.9601 1 18.2401 1.10899 18.454C1.20487 18.6422 1.35785 18.7951 1.54601 18.891C1.75992 19 2.03995 19 2.6 19H7M7 19H13M7 19L7 2.6C7 2.03995 7 1.75992 7.10899 1.54601C7.20487 1.35785 7.35785 1.20487 7.54601 1.10899C7.75992 1 8.03995 1 8.6 1L11.4 1C11.9601 1 12.2401 1 12.454 1.10899C12.6422 1.20487 12.7951 1.35785 12.891 1.54601C13 1.75992 13 2.03995 13 2.6V19M13 9H17.4C17.9601 9 18.2401 9 18.454 9.10899C18.6422 9.20487 18.7951 9.35785 18.891 9.54601C19 9.75992 19 10.0399 19 10.6V17.4C19 17.9601 19 18.2401 18.891 18.454C18.7951 18.6422 18.6422 18.7951 18.454 18.891C18.2401 19 17.9601 19 17.4 19H13" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
                                 </em>
                                 <em>Analytics</em>
                              </a>
                           </li>
                           <li onClick={() => handleRenClick('delete')}>
                              <a href="javascript:void(0);" class="open_modelgry" data-target="dpDeletePop">
                                 <em>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                       <path d="M6 2H10M2 4H14M12.6667 4L12.1991 11.0129C12.129 12.065 12.0939 12.5911 11.8667 12.99C11.6666 13.3412 11.3648 13.6235 11.0011 13.7998C10.588 14 10.0607 14 9.00623 14H6.99377C5.93927 14 5.41202 14 4.99889 13.7998C4.63517 13.6235 4.33339 13.3412 4.13332 12.99C3.90607 12.5911 3.871 12.065 3.80086 11.0129L3.33333 4" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                 </em>
                                 <em>Delete</em>
                              </a>
                           </li>
       
                        </ul>
          </div>
       </div>
    </div>
 </div>
  );
};

export default DeckContentItem;
