import React, { useState, useEffect } from "react";
import DropFile from "./DropFile";
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
const FileUpload = ({ onFileUpload, onComplete, closeModal, activeNavItem, editAction, editFileId }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const handleCloseModal = () => {
    closeModal(); // Close the modal using the function passed as prop
  };

  const [slideInput, setslideInput] = useState('');

  const handleSlideInputChange = (event) => {
   setslideInput(event.target.value);
  };

  const importGslide = async () => {
   if (slideInput) {
     try {
       const formData = new FormData();
       let userId=localStorage.getItem("userId")
       //formdata.append("file", files[0].file);
       formData.append("userId", userId);
       console.log("slidesUrl",slideInput)
       formData.append("slidesUrl", slideInput);
       const headers={
         "Content-Type": "application/json",
         }
       onFileUpload();
       const response = await callAPI(apiUrls.SLIDEDOWNLOAD, {}, "POST",formData,{},headers);
        console.log(response)
         if (response.status == 200) {
           onComplete(response.data.id,response.data.title); 
           console.log('Slide Imorted successfully!');
            // Update state to indicate successful file upload
          // onFileUpload(); // Notify parent component about successful upload
           // Handle success
         //  onUploadComplete();
         } else {
           console.error('Slide Imorted failed.');
           // Handle failure
         }
     } catch (error) {
       console.error('Error occurred while uploading the file:', error);
       // Handle error
     }
   }
 };

 
 const imporYtVideo = async () => {
  if (slideInput) {
    try {
      const formData = new FormData();
      let userId=localStorage.getItem("userId")
      //formdata.append("file", files[0].file);
      formData.append("userId", userId);
      console.log("slidesUrl",slideInput)
      formData.append("vidUrl", slideInput);
      formData.append("slideId", editFileId);
      const headers={
        "Content-Type": "application/json",
        }
      onFileUpload();
      const response = await callAPI(apiUrls.VIDDOWNLOAD, {}, "POST",formData,{},headers);
       console.log("response", response)
        if (response.status == 200) {
          if(response.data.id) {
          onComplete(response.data.id,response.data.title); 
          console.log('Slide Imorted successfully!');
          } else { 
            setErrorMessage(response.data.error);
            onComplete("",response.data.error,true); 
          }
          
           
        } else {
          console.error('Slide Imorted failed.');
          // Handle failure
        }
    } catch (error) {
      console.error('Error occurred while uploading the file:', error);
      // Handle error
    }
  }
};

 const handleDropFileError = (error) => {
  setErrorMessage(error);
};
const label1 = editAction === "imageUpload"?"Add Media":"Add new deck"

  return (
 
            <div class="model_box_child addNewDeckPop" id="addNewDeckPop"  style={{ width: '752px'}}>
         <h4>
            <span class="icon">
               <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path d="M12.0001 22.6667L5.33341 29.3333M20.0001 22.6667L26.6667 29.3333M16.0001 2.66667V5.33334M16.0001 29.3333V22.6667M6.93342 22.6667H25.0667C26.5602 22.6667 27.307 22.6667 27.8774 22.376C28.3792 22.1204 28.7871 21.7124 29.0428 21.2106C29.3334 20.6402 29.3334 19.8935 29.3334 18.4V9.60001C29.3334 8.10653 29.3334 7.3598 29.0428 6.78936C28.7871 6.2876 28.3792 5.87965 27.8774 5.62399C27.307 5.33334 26.5602 5.33334 25.0667 5.33334H6.93341C5.43994 5.33334 4.6932 5.33334 4.12277 5.62399C3.62101 5.87965 3.21306 6.2876 2.9574 6.78936C2.66675 7.3598 2.66675 8.10653 2.66675 9.60001V18.4C2.66675 19.8935 2.66675 20.6402 2.9574 21.2106C3.21306 21.7124 3.62101 22.1204 4.12277 22.376C4.6932 22.6667 5.43994 22.6667 6.93342 22.6667Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
            </span>
            <span>{label1}</span>
            <span class="close_model" onClick={handleCloseModal} >
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
            </span>
         </h4>

         <div class="contentSection">
         {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
         <DropFile
        onFileUpload={onFileUpload}
        onComplete={onComplete}
        closeModal={closeModal}
        activeNavItem={activeNavItem}
        onError={handleDropFileError}
        editAction={editAction}
        editFileId={editFileId}
      />
      
          {editAction !== 'imageUpload' ? (
            <>
            <span class="or flex jcCenter mt-16 mb-24">or</span>
            <div class="uploadForm">
               <p>Add google slides presentation</p>
               <div class="flex alignCenter light">
                  <input type="text" value={slideInput} onChange={handleSlideInputChange} name="" id="" placeholder="Paste google slides link here" class="mr-8" style={{width: '667px', background:"#e5e5e5"}}/>
                  <button class="btn InversePrimary" onClick={importGslide}>Import</button>
               </div>
               <span>Make sure your presentation can be viewed by ‘Anyone with link’.</span>
            </div>
            
            </>
          ):(
            <>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <span class="or flex jcCenter mt-16 mb-24">or</span>
            <div class="uploadForm">
               <p>Import Youtube Video</p>
               <div class="flex alignCenter light">
                  <input type="text" value={slideInput} onChange={handleSlideInputChange} name="" id="" placeholder="Paste Youtube url here here" class="mr-8" style={{width: '667px', background:"#e5e5e5"}}/>
                  <button class="btn InversePrimary" onClick={imporYtVideo}>Import</button>
               </div>
               <span>Make sure your Youtube Video can be viewed by ‘Anyone with link’.</span>
            </div>
            </>
            )}
         </div>
      </div>
    
  );
};


export default FileUpload;
