import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import { constants } from "../utils/constants";

export const isValidEmail = (email) => {
    if (!email || email.trim() === "") {
        return false; // Name is blank
    }
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    return emailRegex.test(email);
  };

  export const isValidName = (name) => {
    if (!name || name.trim() === "") {
        return false; // Name is blank
    }
    const nameRegex = /^[A-Za-z\s]+$/;
    return nameRegex.test(name);
}
  export const getUserPlanDataUtil = async() => {
    const userId=localStorage.getItem("userId")
    const response = await callAPI(apiUrls.GETUSERPLANDATA, {userId:userId}, "GET");
    console.log(response.data);
    if(response.data) {
        localStorage.setItem("upid", JSON.stringify(response.data))
    } else {
        localStorage.setItem("upid", JSON.stringify({planExpired:true}))
    }
  };

  export const checkPlanDataUtil = (eventType) => {
    // Retrieve the stored data
    const upid = JSON.parse(localStorage.getItem("upid"));

    if (upid && (upid.planExpired === true || upid.planExpired === "true")) {
        return false;
      }
    // Access userActualData and userRestData
    const userActualData = upid.userActualData || {};
    const userRestData = upid.userRestData || {};

    // Get the restriction value for the specific eventType
    const restValue = userRestData[eventType] || 0;

    // Calculate the actual value for comparison
    let actualValue;

    if (eventType === constants.T_DU_TOTAL) {
        return true;
        // Special case: Sum the actual values of 1000 and 1015
        actualValue = (userActualData[constants.T_DU_PPT] || 0) + (userActualData[constants.T_DU_PDF] || 0);
    } else {
        // Standard case: Use the actual value of the given eventType
        actualValue = userActualData[eventType] || 0;
    }

    // Perform comparison
    if (restValue > actualValue) {
        console.log(`Event Type ${eventType}: Restriction allows usage.`);
        return true; // Restriction allows usage
    } else {
        console.log(`Event Type ${eventType}: Restriction exceeded or no usage allowed.`);
        return false; // Restriction exceeded or usage not allowed
    }
};

export const checkIstrialPlan = async () => {
    // const userId=localStorage.getItem("userId")
    // const response = await callAPI(apiUrls.GETUSERPLANDATA, {userId:userId}, "GET");
    // console.log(response.data);
    // if(response.data) {
    //     localStorage.setItem("upid", JSON.stringify(response.data))
    // } else {
    //     localStorage.setItem("upid", JSON.stringify({planExpired:true}))
    // }
    await getUserPlanDataUtil();
    const upid = JSON.parse(localStorage.getItem("upid"));
    if (upid && upid.trialPlan && (upid.trialPlan.isTrial === true || upid.trialPlan.istrial === "true")) {
        return upid.trialPlan.expiry;
      } else {
        return false
      }

}

// export const formatDateAsTh = (dateString) => {
//   const date = new Date(dateString);

//   // Extract day and month
//   const day = date.getUTCDate();
//   const month = date.toLocaleString('en-US', { month: 'short' }); // Get short month name

//   // Determine ordinal suffix for the day
//   const suffix = (day) => {
//       if (day % 10 === 1 && day !== 11) return 'st';
//       if (day % 10 === 2 && day !== 12) return 'nd';
//       if (day % 10 === 3 && day !== 13) return 'rd';
//       return 'th';
//   };

//   return `${day}${suffix(day)} ${month}`;
// }

export const formatDateAsTh = (dateString) => {
  const date = new Date(dateString);

  // Extract day, month, and year
  const day = date.getUTCDate();
  const month = date.toLocaleString('en-US', { month: 'short' }); // Get short month name
  const year = date.getUTCFullYear();

  // Determine ordinal suffix for the day
  const suffix = (day) => {
      if (day % 10 === 1 && day !== 11) return 'st';
      if (day % 10 === 2 && day !== 12) return 'nd';
      if (day % 10 === 3 && day !== 13) return 'rd';
      return 'th';
  };

  // Check if the input string includes a year
  const includesYear = /\d{4}/.test(dateString);

  // Return formatted date with a comma before the year
  return includesYear ? `${day}${suffix(day)} ${month}, ${year}` : `${day}${suffix(day)} ${month}`;
};



