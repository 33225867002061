import React, { createContext, useContext, useState, useEffect } from "react";

const UndoContext = createContext();

export const useUndoContext = () => useContext(UndoContext);

export const UndoProvider = ({ children }) => {
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [isContextModalOpen, setIsContextModalOpen] = useState(false); // Track modal state

  const addUndoAction = (action) => {
    setUndoStack([...undoStack, action]);
    setRedoStack([]); // Clear redo stack on new action
  };

  const handleUndo = () => {
    if (isContextModalOpen || undoStack.length === 0) return; // Block if modal is open
    const lastAction = undoStack.pop();
    lastAction.undoHandler();
    setRedoStack([lastAction, ...redoStack]);
  };

  const handleRedo = () => {
    if (isContextModalOpen || redoStack.length === 0) return; // Block if modal is open
    const lastRedo = redoStack.shift();
    lastRedo.redoHandler();
    setUndoStack([...undoStack, lastRedo]);
  };

  // Key listener for undo/redo
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "z") {
        event.preventDefault();
        handleUndo();
      } else if ((event.ctrlKey || event.metaKey) && event.key === "y") {
        event.preventDefault();
        handleRedo();
      }
        
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [undoStack, redoStack, isContextModalOpen]);

  const value = {
    addUndoAction,
    setIsContextModalOpen, // Expose modal state setter
    handleUndo,
    handleRedo,
  };

  return <UndoContext.Provider value={value}>{children}</UndoContext.Provider>;
};
export const useUndo = () => useContext(UndoContext);