import React, { useState, useEffect } from 'react';
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from "@react-oauth/google";
import { isValidEmail, isValidName } from "../utils/utilFn";

import CustomGoogleButton from '../components/CustomGoogleButton';

const Login = ({ handleLogin }) => {
   const [username, setUsername] = useState('');
   const [name, setName] = useState('');
   
   const [password, setPassword] = useState('');
   const [loginSession, setLoginSession] = useState('');
   const [errorNameMessage, setErrorNameMessage] = useState(' ');
   const [errorMessage, setErrorMessage] = useState('');
   const [showLoginBox, setShowLoginBox] = useState(false);
   const [showSignUpBox, setShowSignUpBox] = useState(false);
   const [showPricingBox, setShowPricingBox] = useState(false);
   const [codeBoxStatus, setCodeBoxStatus] = useState("NONE");
   const [plans, setPlans] = useState([]);
   const [displayPlan, setDisplayPlan] = useState("month");
   const [isChecked, setIsChecked] = useState(true); // Checkbox is checked by default

   const handleCheckboxChange = (event) => {
     setIsChecked(event.target.checked); // Update the state when checkbox is clicked
   };
   useEffect(() => {
      const fetchData = async () => {
        try {

          const response = await callAPI(apiUrls.GETPAYMENTPLANS, {}, "GET");

          console.log(response);
          setPlans(response.data.plans); 

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData(); // Call the fetchData function when the component mounts
    }, []);
   const handleLoginClick = async (e) => {
      console.log("hhhhh")
      e.preventDefault();
      try {
         if (!username) {
            setErrorMessage('Please enter email.');
         } else {
            setErrorMessage('');
            const formData = new FormData();
            //formdata.append("file", files[0].file);
            formData.append("email", username);
            //   formData.append("password", password);
            //formData.append("code", password);
            //formData.append("session", "AYABeGsVFPg0CL151TBvijf41vsAHQABAAdTZXJ2aWNlABBDb2duaXRvVXNlclBvb2xzAAEAB2F3cy1rbXMATGFybjphd3M6a21zOmFwLXNvdXRoLTE6NjU0NDM0NDQ0NzkwOmtleS8yNjg1NWU1NC05NTMzLTRhNDctYjYxNy1hYjgwYjMyNDkxZWQAuAECAQB4IiNWL_vNLuA_HYRb4PrTYxCHn3uVXc9cwgKEMGhCAIsB3uzmMqZEIMXBkL4D8AzRdwAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDEdNhttHOqm509PHlgIBEIA7HMi5gjYk0Jla8yGTfJaBzqmuM8_tUIwKAmLsMnuelGmbiXKfyLH7krV6iFMmn3cBlt-xA0xculTOgH0CAAAAAAwAABAAAAAAAAAAAAAAAAAAZ8q7MTX3Eb8tErMAgSwwU_____8AAAABAAAAAAAAAAAAAAABAAABLaXfDK5fc4JmIrGgHewLPBVLEcMyj6H1DaORrNOzXweaYkjS1TOWXLtk54ZtiyngpvWnOAg49W3ANKyDwOYKgerD30aijMGlj61cZXEqSBXq6mh5SHboalNvmwBeDNdivyv19HI0XP3RASlNtro5ccBY-6q8RqjyyQ2bB__tPfNO894_9ztP-hRvKtFC-Alc0WP4gk_Ep-S5L-5_YWwNsAPWFOzm7Jmb9JNFa_lRfGLplkz8GorMUVjExP9BGKwq6Ppbr7QmvX9Qc0b3qGtHv8L7aE2sdSt3YP9IhsUHY_5DSJpKqyJro18pukclCL7aO7EunkuF8ugD-rMWenBV8i7w73xFzamqjvUa6iT3jBHUlPLkRP4AqpmAzGQ93YxbWLSdqFHMFFYWzRSsLCLf0XMUmBza5nePrbB8NQRz");
           
            const headers = {
               "Content-Type": "application/json",
            }
            
           
             const response = await callAPI(apiUrls.COGNITOLOGIN, {}, "POST",formData,{},headers);
            // Here, you might perform validation before calling handleLogin
            // For simplicity, let's assume the login is successful immediately
            console.log(response);
            if (response.data.sentStatus === "SENT") {
               setErrorMessage(response.data.message)
               setCodeBoxStatus("SENT")
               setLoginSession(response.data.session)
               //localStorage.setItem("userId", response.data.userId)

               //const initial = (response?.data?.name?.charAt(0) ?? response?.data?.email?.charAt(0) ?? 'U').toUpperCase();
               //localStorage.setItem("initial", initial)
               //handleLogin(); // Call handleLogin function passed from parent component
               console.log("bbbb")
            } else if(response.data.status === "UNCONFIRMED") {
               console.log("UNCONF")
               setShowSignUpBox(true)
               setShowLoginBox(false)
                  setCodeBoxStatus("UNCONFIRMED")
                  setErrorMessage(response.data.message)
                 // setErrorMessage('Invalid username or password.');
               
            } else if(response.data.status === "NOTEXIST") {
               const msg = (
                  <>
                    User does not exist. Please <a href="javascript:void(0);" onClick={signupClick} style={{ color: "#00BFA5" }}>SignUp.</a>
                  </>
                );
                setErrorMessage(msg);
            } else {
              
               setErrorMessage(response.data.message)
            }


         }
      } catch (error) {
         console.error('An error occurred:', error);
      }

   };

   const handleSignUpClick = async (e) => {
      console.log("SignUp")
      setErrorMessage("")
      setErrorNameMessage("")
      e.preventDefault();
      try {
         if(!isValidName(name)){
            setErrorNameMessage('Please enter valid name.');
         } else if (!isValidEmail(username)) {
            setErrorMessage('Please enter valid email.');
         } else if (!isChecked) {
            setErrorMessage('Please accept Privacy Policy and Terms & Conditions.');
         }  else {
            setErrorMessage('');
            const formData = new FormData();
            formData.append("email", username);
            formData.append("name", name);
       
            console.log("Username:", username);
            console.log("Password:", password);

            const headers = {
               "Content-Type": "application/json",
            }
            // const response = await callAPI(apiUrls.LOGIN, {}, "POST",formData,{},headers);
                const response = await callAPI(apiUrls.COGNITOREGISTER, {}, "POST",formData,{},headers);
            //    const response = await callAPI(apiUrls.COGNITOEMAILVERIFY, {}, "POST",formData,{},headers);

     //       const response = await callAPI(apiUrls.COGNITOVERIFY, {}, "POST", formData, {}, headers);
            // const response = await callAPI(apiUrls.COGNITOLOGIN, {}, "POST",formData,{},headers);
            // Here, you might perform validation before calling handleLogin
            // For simplicity, let's assume the login is successful immediately
            console.log(response);
            if (response.data.status === "REGISTERED") {
               setErrorMessage(response.data.message)
               setCodeBoxStatus("SENT")
               setLoginSession(response.data.session)
               loginClick(response.data.message);
            } else if(response.data.status === "UNCONFIRMED") {
               setCodeBoxStatus("UNCONFIRMED")
               setErrorMessage(response.data.message)
              // setErrorMessage('Invalid username or password.');
            } else {
               const msg = (
                  <>
                      
                      User already exists. Please <a href="javascript:void(0);"  onClick={() => {
                           loginClick();
                        }} style={{ color: "#00BFA5" }}>Login.</a>
                  </>
                );
                setErrorMessage(msg);
               //setErrorMessage(response.data.message)
            }

         }
      } catch (error) {
         console.error('An error occurred:', error);
      }

   };
   const sentConfirmCode = async (e) => {
      console.log("ConfirmCode")
      e.preventDefault();
      try {
         if (!username) {
            setErrorMessage('Please enter email.');
         } else {
            setErrorMessage('');
            const formData = new FormData();
            formData.append("email", username);
                  const headers = {
               "Content-Type": "application/json",
            }
            
            const response = await callAPI(apiUrls.COGNITORESENDCODE, {}, "POST",formData,{},headers);
            console.log(response);
            if (response.data.sentStatus === "SENT") {
               setErrorMessage(response.data.message)
               setCodeBoxStatus("SENT")
            }  {
               setErrorMessage(response.data.message)
            }


         }
      } catch (error) {
         console.error('An error occurred:', error);
      }

   };
   
   const handleLoginVerify = async (e) => {
      console.log("ConfirmCodeLogin")
      e.preventDefault();
      try {
         if (!username) {
            setErrorMessage('Please enter email.');
         } else {
            setErrorMessage('');
            const formData = new FormData();
            formData.append("email", username);
            formData.append("code", password);
            formData.append("session", loginSession);
                  const headers = {
               "Content-Type": "application/json",
            }
            const response = await callAPI(apiUrls.COGNITOVERIFY, {}, "POST",formData,{},headers);
            console.log(response);
            if (response.data.status === "LOGIN") {
               setErrorMessage(response.data.message)
               setCodeBoxStatus("VERIFIED")
                localStorage.setItem("userId", response.data.userId)
             //   localStorage.setItem("tokens", response.data.tokens)
               localStorage.setItem("accessToken", response.data.tokens.AccessToken)
               localStorage.setItem("idToken", response.data.tokens.IdToken)

               const name = response?.data?.name ?? response?.data?.email ?? 'User'; // Default to 'Unknown' if neither name nor email exists
               const initial = name.charAt(0).toUpperCase(); // Get the initial

               // Store both in localStorage
               localStorage.setItem("initial", initial);
               localStorage.setItem("name", name);

               handleLogin(); // Call handleLogin function passed from parent component
            } else if(response.data.status === "INVALID_CODE") {
               setLoginSession(response.data.session)
               setErrorMessage(response.data.message)
            } else {
               setErrorMessage(response.data.message)
            }


         }
      } catch (error) {
         console.error('An error occurred:', error);
      }

   };
   const verifyEmail = async (e) => {
      console.log("ConfirmCode")
      e.preventDefault();
      try {
         if (!username) {
            setErrorMessage('Please enter both email.');
         } else {
            setErrorMessage('');
            const formData = new FormData();
            formData.append("email", username);
            formData.append("code", password);
                  const headers = {
               "Content-Type": "application/json",
            }
            const response = await callAPI(apiUrls.COGNITOEMAILVERIFY, {}, "POST",formData,{},headers);
            console.log(response);
            if (response.data.sentStatus === "VERIFIED") {
               setErrorMessage(response.data.message)
               setCodeBoxStatus("VERIFIED")
            }  {
               setErrorMessage(response.data.message)
            }


         }
      } catch (error) {
         console.error('An error occurred:', error);
      }

   };

   const loginClick = (msg = "") => {
      setErrorMessage(msg)
      setShowLoginBox(true)
      setShowSignUpBox(false)
      setShowPricingBox(false)
   }
   const signupClick = () => {
      setErrorMessage("")
      setErrorNameMessage("")
      setShowSignUpBox(true)
      setShowLoginBox(false)
      setShowPricingBox(false)
   }
   const pricingClick = () => {
      setErrorMessage("")
      setErrorNameMessage("")
      setShowSignUpBox(false)
      setShowLoginBox(false)
      setShowPricingBox(true)
   };
   const handlePlanClick =(paymentPlan) => {
      console.log()
      sessionStorage.setItem("paymentPlan", paymentPlan.pid)
      signupClick();
   }
   const validPlans = [
      { id: "A", name: "Basic Plan", price: "$10", description: "Basic access to features" },
      { id: "B", name: "Premium Plan", price: "$20", description: "Premium access with additional features" },
      { id: "C", name: "Enterprise Plan", price: "$30", description: "Full access to all features and priority support" },
    ];
    const [isMobile, setIsMobile] = useState(false);
   return (
<>
      <div class="container">

         {(!showLoginBox && !showSignUpBox) &&
            <div className="headerWrap">
               <div className="header">
                  <div className="deckLogo">
                     {/* Logo or branding */}
                     <a href={window.location.origin} target="_blank">
                     <svg width="40" height ="40" id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 201.9 201.9">
                     <path d="M63.8,122.6c-.1-1.4.5-2.7,1.6-3.6l53.8-41.2c.8-.6,0-1.9-.9-1.4l-68.2,38.6c-.6.3-1.2.5-1.9.5l11.1,50c0,1.7,2.1,2.6,3.3,1.4l23.6-30.2-20.2-10.9c-1.3-.7-2.1-1.9-2.2-3.3Z" fill="white"/>
                     <path d="M178,34.4c-.3,0-.5,0-.7,0-.2,0-.3,0-.5.1L22.4,88.7c-.3.1-.6.3-.8.5-2.9,3.3-.1,6.9.7,7.8.2.2.3.3.5.4l24.4,13.7c.2.1.4.2.6.2.1,0,.2,0,.4,0,.3,0,.6,0,1-.2l112.7-62.3-92.8,71.7c-1.1.9-1,2.6.3,3.3l20.3,10.9c.1,0,.2.1.3.2,5.9,3.2,11.5,6.7,17.2,10.3,2.5,1.6,4.8,2.2,6.9,2.2,6.9,0,10.7-7.1,10.7-7.1,0,0,46.4-83.6,54-95.6,6-9.5,1.5-10.5-.6-10.5Z" fill="white"/>
                     </svg>
                     </a>
                  </div>
                  <div className="rSec">
                  <button className="btn PrimaryOutline">
                       <a href="https://blog.deck.work" target="new" >
                        Know About Deck
                     </a>
                     </button>
                  <button
                        href="javascript:void(0);"
                        className="btn PrimaryOutline"
                        onClick={() => {
                           pricingClick();
                        }}
                     >
                        Pricing
                     </button>
                     <button
                        href="javascript:void(0);"
                        className="btn PrimaryOutline"
                        onClick={() => {
                           signupClick();
                        }}
                     >
                        SignUp
                     </button>
                     <button
                        href="javascript:void(0);"
                        className="btn PrimaryOutline"
                        onClick={() => {
                           loginClick();
                        }}

                     >
                        Login
                     </button>
                  </div>
               </div>
            </div>
         }
         <div class="wrapper flex alignCenter mt-24">
            <div class="loginSection" style={{ minWidth: '380px' }}>
               <div class="headDeck">
               {!showPricingBox && (
                  <div class="logo">
                     <div class="logoImg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="158" height="53" viewBox="0 0 158 53" fill="none">
                           <path d="M23.1627 51.668H0.5V0H23.1627C26.4223 0.0467372 29.1347 0.420635 31.2998 1.12169C34.9877 2.31349 37.9737 4.49846 40.2579 7.67659C42.0899 10.2471 43.339 13.028 44.0052 16.0192C44.6714 19.0104 45.0045 21.8613 45.0045 24.5721C45.0045 31.4425 43.6007 37.2612 40.7932 42.0284C36.9863 48.4548 31.1095 51.668 23.1627 51.668ZM31.3355 13.1799C29.6462 10.3757 26.3033 8.97355 21.3068 8.97355H11.1711V42.6944H21.3068C26.4937 42.6944 30.1102 40.1823 32.1564 35.1581C33.2747 32.4006 33.8338 29.1173 33.8338 25.3082C33.8338 20.0503 33.001 16.0075 31.3355 13.1799Z" fill="#D9D9D9" />
                           <path d="M81.7773 40.4511C81.5156 42.7178 80.3141 45.0196 78.1727 47.3565C74.8417 51.0721 70.1783 52.9299 64.1825 52.9299C59.2336 52.9299 54.8676 51.3642 51.0846 48.2328C47.3015 45.1014 45.41 40.0071 45.41 32.9497C45.41 26.3364 47.1111 21.2654 50.5135 17.7368C53.9397 14.2081 58.3771 12.4438 63.8256 12.4438C67.0615 12.4438 69.9761 13.0397 72.5695 14.2315C75.1629 15.4233 77.3043 17.3045 78.9936 19.875C80.5163 22.1418 81.5037 24.7707 81.9558 27.7619C82.2175 29.5145 82.3246 32.0384 82.277 35.3333H55.6528C55.7955 39.1658 57.0209 41.8532 59.3288 43.3955C60.7326 44.3536 62.4218 44.8327 64.3967 44.8327C66.4904 44.8327 68.1916 44.2485 69.5002 43.08C70.214 42.4491 70.8445 41.5728 71.3918 40.4511H81.7773ZM71.713 28.7434C71.5464 26.1027 70.7256 24.1047 69.2504 22.7493C67.799 21.3706 65.9908 20.6812 63.8256 20.6812C61.4701 20.6812 59.6381 21.4056 58.3295 22.8545C57.0447 24.3034 56.2357 26.2663 55.9026 28.7434H71.713Z" fill="#D9D9D9" />
                           <path d="M118.8 27.166H108.379C108.188 25.7405 107.701 24.4552 106.915 23.3102C105.773 21.7679 104.001 20.9967 101.598 20.9967C98.1716 20.9967 95.828 22.6675 94.5669 26.0093C93.9007 27.7853 93.5676 30.1455 93.5676 33.0899C93.5676 35.8942 93.9007 38.1493 94.5669 39.8552C95.7804 43.0333 98.0645 44.6224 101.419 44.6224C103.799 44.6224 105.488 43.9914 106.487 42.7295C107.486 41.4676 108.093 39.8318 108.307 37.8221H118.693C118.455 40.86 117.337 43.7343 115.338 46.4451C112.15 50.815 107.427 53 101.169 53C94.9119 53 90.308 51.1772 87.3577 47.5317C84.4074 43.8862 82.9322 39.1541 82.9322 33.3353C82.9322 26.7687 84.562 21.6627 87.8217 18.0172C91.0813 14.3717 95.5781 12.5489 101.312 12.5489C106.19 12.5489 110.175 13.6239 113.268 15.7738C116.385 17.9237 118.229 21.7211 118.8 27.166Z" fill="#D9D9D9" />
                           <path d="M157.5 51.668H145.223L135.908 35.3333L131.697 39.6448V51.668H121.704V0.175265H131.697V28.0073L144.366 13.6356H156.965L143.367 28.2526L157.5 51.668Z" fill="#D9D9D9" />
                        </svg>
                     </div>
                     <div class="betaTag">
                        BETA
                     </div>
                  </div>
                   )}
                  {!showLoginBox && !showSignUpBox && !showPricingBox && (
                  <div className="headTxt mt-12">
                     <h3>Presentations Powered by You and AI</h3>
                  </div>
                  )}
               </div>
     
               {(showLoginBox || showSignUpBox) ?( <>
                  {showLoginBox ? (
                     <>
                        <div class="headTxt mt-24">
                           <h3>Sign in</h3>
                        </div>
                        
                           
                           <div class="dForm dbBox mt-12 gLoginForm">
                              <div class="row gap24 flexColum w100">
                              {codeBoxStatus === "SENT" ? (
                                     <div class="col flex flexColum w100">
                                     <label class="mb-8">Enter the Verification code below</label>
                                     <input type="password" name="" placeholder="Enter Verification Code" class="w100" id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required />
                                        <div style={{ height: '20px', width: '320px', color: '#DD2C00', textAlign: 'left' }}>
                                            {errorMessage && <p style={{ color: '#00BFA5'}}>{errorMessage}</p>}
                                             </div>
                                      
                                  </div>
                                 ): (
                                   
                                    <div class="col flex flexColum w100">
                                       <label class="mb-8">Email</label>
                                       <input type="text" name="" placeholder="Email" class="w100" id="username"
                                          value={username}
                                          onChange={(e) => setUsername(e.target.value)} />
                                            <div style={{ height: '20px', width: '320px', color: '#DD2C00', textAlign: 'left' }}>
                                            {errorMessage && <p style={{ color: '#DD2C00'}}>{errorMessage}</p>}
                                             </div>
                                        
                                    </div>
                                 )}
                              </div>
                              <div class="actionButtons jcflxStart w100">
                                 { codeBoxStatus === "SENT" ? (
                                    <button class="btn PrimaryOutline" type="submit"  onClick={handleLoginVerify}>Verify</button>   
                                 ) : (   
                                 <button class="btn PrimaryOutline" type="submit"  onClick={handleLoginClick}>Log In</button>
                                 )}  
                              </div>
                              <div>Don't have an account? <a href="javascript:void(0);" onClick={signupClick} style={{ color: "white", textDecoration:"underline"}}>Sign up for Deck</a></div>
                           </div>
                          
                     </>
                  ) : (
                     <>
                        <div class="headTxt mt-24">
                           <h3>Sign Up</h3>
                        </div>
                  
                           
                           <div class="dForm dbBox mt-12 gLoginForm">
                              {codeBoxStatus === "VERIFIED" ? (
                                 <div class="row gap24 flexColum w100">
                                 <div class="col flex flexColum w100">
                                    Your Email is verifed Please <a  href="javascript:void(0);" onClick={() => {loginClick();}} style={{color:"#00BFA5"}}> login</a>
                                 </div>
                              </div>
                               ) :  (

                                 <>
                                 <div class="row gap24 flexColum w100">
                                 {codeBoxStatus === "SENT" ? (
                                     <div class="col flex flexColum w100">
                                     <label class="mb-8">Code</label>
                                     <input type="password" name="" placeholder="Enter Verification Code" class="w100" id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required />
 
                                  </div>
                                 ): (
                                   <>
                                          {(codeBoxStatus !== "UNCONFIRMED" && codeBoxStatus !== "SENT") && <div class="col flex flexColum w100">
                                          <label class="mb-8">Full Name</label>
                                          <input type="text" name="" placeholder="Name" class="w100" id="name"
                                             value={name}
                                             onChange={(e) => setName(e.target.value)} />
                                            <div style={{ height: '20px', width: '320px', color: '#DD2C00', textAlign: 'left' }}>
                                                {errorNameMessage && <p style={{ color: '#DD2C00' }}>{errorNameMessage}</p>}
                                                </div>

                                       </div>
                                 }
                                    <div class="col flex flexColum w100">
                                       <label class="mb-8">Email</label>
                                       <input type="text" name="" placeholder="Email" class="w100" id="username"
                                          value={username}
                                          onChange={(e) => setUsername(e.target.value)} />
                                            <div style={{ height: '20px', width: '320px', color: '#DD2C00', textAlign: 'left' }}>
                                            {errorMessage && <p style={{ color: '#DD2C00'}}>{errorMessage}</p>}
                                             </div>
                                          
                                    </div>
                             
                                       </>
                                  
                                 )}

                                 </div>

                                 <div style={{ display: "flex", paddingTop:"0px"}}>

                                       <input
                                       type="checkbox"
                                       checked={isChecked}
                                       onChange={handleCheckboxChange}
                                       style={{appearance:"checkbox" , accentColor:"green"}}
                                       />
                                       <label className="custom-checkbox-container" style={{ color: "white", marginTop:"8px", marginLeft:"10px"}}>
                                       I agree to the &nbsp;<a href="https://blog.deck.work/privacy-policy" target="new" style={{ color: "lightblue"}}>Privacy Policy</a>&nbsp; and <a href="https://blog.deck.work/terms-and-conditions" target="new" style={{ color: "lightblue"}}> &nbsp;Terms & Conditions.</a>

                                       </label>
                                 </div>
                                 
                                 <div class="actionButtons jcflxStart w100">
                                    {codeBoxStatus === "UNCONFIRMED"  ? ( 
                                    <button class="btn PrimaryOutline" type="submit"  onClick={sentConfirmCode} >Confirm</button>
                                    ): codeBoxStatus === "SENT" ? (
                                       <button class="btn PrimaryOutline" type="submit"  onClick={verifyEmail} >Verify</button>
                                    ) :(
                                       <button class="btn PrimaryOutline" type="submit"  onClick={handleSignUpClick} >SignUp</button>
                                    )}
                                 </div>
                                 </>
                               )}
        
                               <div>Already have an account? <a href="javascript:void(0);"  onClick={() => loginClick('')} style={{ color: "white", textDecoration:"underline" }}>Log in here.</a></div> 
                               
                           </div>
                        
                     </>
                  )}

                   <GoogleOAuthProvider clientId="1039487477793-8iv47jsvilbe8ug57q9503o79u55a4pv.apps.googleusercontent.com" scope="https://www.googleapis.com/auth/presentations">
                     <CustomGoogleButton handleLogin={handleLogin} />
                  </GoogleOAuthProvider> 
               </>
              ) : showPricingBox ? (
               <>
               
               </>
            ) : (
               <>
               <div class="vidBox deckVideo mt-12" style={isMobile ? {} : { width: "711px", height: "410px" , border:"none", borderRadius:0}}>
               <iframe src="https://player.vimeo.com/video/884395322?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1"
               //  width="1920" height="1080"
                  style={isMobile ? { width: '100%', height: '100%' } : { width: "100%", height: "100%" }}
                 frameborder="0" 
                 allow="autoplay; fullscreen; picture-in-picture"
                  title="Deck Product_Shorter"></iframe> 
               </div>
                        <div class="deckAccessLog">
                        
                        <div class="dForm mt-12">
                        <span style={{margin:"20px", fontWeight:"bold"}}><a href="https://blog.deck.work/privacy-policy" target="new">Privacy Policy</a></span><span style={{margin:"20px", fontWeight:"bold"}}><a href="https://blog.deck.work/terms-and-conditions" target="new">Terms & conditions</a></span>
                        </div>
                     </div>
                     </>
               )}

            </div>
         </div>
      </div>

      




{showPricingBox && (
   <>
    <div class="dashContent deckDashboard ml-32">
          <div class="alignCenter" style={{width:"100%", textAlign:"center"}}><h1 class="alignCenter"  style={{ textAlign:"center", display:"block"}}>Pricing Plans</h1></div>
          <div class="alignCenter" style={{width:"100%", textAlign:"center"}}><h3 class="alignCenter"  style={{ textAlign:"center", display:"block", margin:"20px"}}>Simple, transparent pricing that grows with you. Free trial for first 14 days.</h3></div>
          <div class="alignCenter" style={{ width:"100%", textAlign:"center"}}> 
          <div class="alignCenter btn PrimaryOutline" style={{ textAlign:"center", padding:"5px 5px"}}> 
          <button
  className={`btn ${displayPlan === "month" ? "PrimaryOutline" : ""}`}
  style={
    displayPlan === "month"
      ? { background: "rgb(217, 217, 217)", color: "black" }
      : { color: "white" }
  }
  onClick={() => setDisplayPlan("month")}
>
  Monthly billing
</button>
<button
  className={`btn ${displayPlan === "year" ? "PrimaryOutline" : ""}`}
  style={
    displayPlan === "year"
      ? { background: "rgb(217, 217, 217)", color: "black" }
      : { color: "white" }
  }
  onClick={() => setDisplayPlan("year")}
>
  Annual billing
</button>

          </div>    
          <div style={{color:"#00BFA5"}}>You save 23% by paying annually</div>
          </div>   
          <div class="dForm deckListing mt-24">
            <div class="rowList flexWrap">
          
                  {plans.map((plan, index) => {
                  const description = JSON.parse( plan.description);
                  const isShow = displayPlan === "year" 
                ? description.duration.includes("year") 
                : displayPlan === "month" 
                  ? description.duration.includes("month") 
                  : false;
                  return (
                    <>
                    {isShow && (
                    <div class="itemWrap dbBox " style={{width:"30%", margin:"10px", padding:"0px", justifyContent:"unset", minHeight:"600px" }}>
            <div class="item row jcSpacebetween gap24">
                       
                         <div class="col mrAuto main_navigation" style={{borderRight:"none"}}>
                          <span style={{marginBottom:"100px"}}><h3>{plan.title}</h3></span>
                           <span> <h2>{description.priceLabel}</h2></span>
                           
                         <button className="btn PrimaryOutline" style={{ background:"rgb(217, 217, 217)", color:"black"}}  onClick={() => {handlePlanClick(plan); }}>
                            Select Plan
                        </button>
                       
                    
                         
                           <span style={{borderBottom:"2px solid #424242"}}>&nbsp;</span>
                           <span  style={{marginTop:"20px",  color: "#00BFA5"}}> {description.summary}</span>
                           <ul class="nav" style={{marginTop:"20px"}}>
                           {description.features.map((feature, featureIndex) => (
                           
                           <li><span><svg fill= "#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
                           width="20px" height="20px" viewBox="0 0 305.002 305.002"
                           >
                       <g>
                          <g>
                             <path d="M152.502,0.001C68.412,0.001,0,68.412,0,152.501s68.412,152.5,152.502,152.5c84.089,0,152.5-68.411,152.5-152.5
                                S236.591,0.001,152.502,0.001z M152.502,280.001C82.197,280.001,25,222.806,25,152.501c0-70.304,57.197-127.5,127.502-127.5
                                c70.304,0,127.5,57.196,127.5,127.5C280.002,222.806,222.806,280.001,152.502,280.001z"/>
                             <path d="M218.473,93.97l-90.546,90.547l-41.398-41.398c-4.882-4.881-12.796-4.881-17.678,0c-4.881,4.882-4.881,12.796,0,17.678
                                l50.237,50.237c2.441,2.44,5.64,3.661,8.839,3.661c3.199,0,6.398-1.221,8.839-3.661l99.385-99.385
                                c4.881-4.882,4.881-12.796,0-17.678C231.269,89.089,223.354,89.089,218.473,93.97z"/>
                          </g>
                       </g>
                       </svg></span><span>{feature}</span>
                       </li>
                          ))}
                          </ul>



                         </div>
                         <div class="col" style={{textAlign:"center", width:"100%"}}>
                         {/* <a href="javascript:void(0);" className="btn PrimaryOutline"  onClick={handleUpgradeClick} >Upgrade Plan</a> */}
                      

                         </div>
                      </div>
        
                   </div>
                    )}
                   </>
                  );
})}
                   
              <div class="itemWrap dbBox " style={{width:"30%", margin:"10px", padding:"0px", justifyContent:"unset", minHeight:"600px" }}>
                <div class="item row jcSpacebetween gap24">
                  
                    <div class="col mrAuto main_navigation" style={{borderRight:"none"}}>
                    <span><h3>Deck Enterprise</h3></span>
                      <span  style={{marginBottom:"85px",  color: "#00BFA5"}}> Scale with custom features for individuals or corporates.</span>
                      
                    <button className="btn PrimaryOutline" style={{ background:"rgb(217, 217, 217)", color:"black"}}  >
                      Get in touch
                  </button>
                    </div>
                    <div class="col" style={{textAlign:"center", width:"100%"}}>
                  
                    </div>
                </div>
  
              </div>
        
             </div>
             
          </div>
      </div>
</>
)}
</>
   );
};

export default Login;
