import React from 'react';

import { useGoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";

function CustomGoogleButton({ handleLogin }) {

  const googleLogin = useGoogleLogin({
 
    onSuccess: async (codeResponse) => {

      try {

      console.log('Google login successful', codeResponse);
      
     //   let object = jwtDecode(credentialResponse.credential);
        console.log(codeResponse.code)
        let formdata={
           code:codeResponse.code
         }
         console.log("ssssssssssssssssssssssskkkkkkkkkk",formdata);
         const apiResponse = await callAPI(apiUrls.GLOGIN, {}, "POST", formdata);
         console.log(apiResponse,"apiiiiiiiii");
         if (apiResponse && apiResponse.data && typeof apiResponse.data.userId !== 'undefined') {
          // userId is defined
          localStorage.setItem("userId", apiResponse.data.userId);
          const initial = (apiResponse?.data?.name?.charAt(0) ?? apiResponse?.data?.email?.charAt(0) ?? 'U').toUpperCase();
          localStorage.setItem("initial",initial)
        } 
         
         
         
      
         // console.log(credentialResponse);
     
         handleLogin();
     } catch (error) {
        console.error('Error decoding token:', error);
        // Handle decoding errors here
      }

      // You can now use the tokenResponse to authenticate the user in your app

    } ,

    onError: () => {

      console.error('Google login failed');

      // Handle login errors here

    },
    //auto_select: true,
   flow: 'auth-code', // Use 'auth-code' for the authorization code flow
//   scope: "https://www.googleapis.com/auth/presentations"
scope: "https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/presentations.readonly"
     
  });


  return (

    <div class="gLoginBtn" >
<a href="javascript:void(0);" onClick={() => googleLogin()}>
 <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                 <g clip-path="url(#clip0_442_2883)">
                   <path d="M24.266 12.2764C24.266 11.4607 24.1999 10.6406 24.0588 9.83807H12.74V14.4591H19.2217C18.9528 15.9494 18.0885 17.2678 16.823 18.1056V21.1039H20.69C22.9608 19.0139 24.266 15.9274 24.266 12.2764Z" fill="#4285F4"/>
                   <path d="M12.74 24.0008C15.9764 24.0008 18.7058 22.9382 20.6944 21.1039L16.8274 18.1055C15.7516 18.8375 14.3626 19.252 12.7444 19.252C9.61376 19.252 6.95934 17.1399 6.00693 14.3003H2.01648V17.3912C4.05359 21.4434 8.20278 24.0008 12.74 24.0008Z" fill="#34A853"/>
                   <path d="M6.00253 14.3003C5.49987 12.8099 5.49987 11.1961 6.00253 9.70575V6.61481H2.01649C0.31449 10.0056 0.31449 14.0004 2.01649 17.3912L6.00253 14.3003Z" fill="#FBBC04"/>
                   <path d="M12.74 4.74966C14.4508 4.7232 16.1043 5.36697 17.3433 6.54867L20.7694 3.12262C18.6 1.0855 15.7207 -0.034466 12.74 0.000808666C8.20277 0.000808666 4.05359 2.55822 2.01648 6.61481L6.00252 9.70575C6.95052 6.86173 9.60935 4.74966 12.74 4.74966Z" fill="#EA4335"/>
                 </g>
                 <defs>
                   <clipPath id="clip0_442_2883">
                     <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                   </clipPath>
                 </defs>
               </svg>
                    <span>Sign up with Google</span>
                  </a>

    </div>

  );

}


export default CustomGoogleButton;
