export const constants = {
    T_P_EM: 2001, //stored email address who is viewing deck
    T_E_P_SV:2002, //slide view in edit mode
    T_P_SV:2003, //slide view in presentation mode
    T_E_P_TS:2004, //Time spent on slide in edit mode
    T_P_TS:2005, //Time spent on slide in presentation mode
    T_E_P_FSV:2006, //First slide view in edit mode
    T_P_FSV:2007, //First slide view in presentation mode
    T_P_FEM:2008, //First time email address stored who is viewing deck
    T_P_AP:2009, //Audio Played while presentation view
    T_E_P_AP:2010, ////Audio Played while edit view


    T_DU_TOTAL:1016, //deck uploaded Total - this is not tracked but it is sum of 1000 amd 1015
    T_DU_PPT:1000, //deck uploaded PPT
    T_DU_PDF:1015, //deck uploaded PDF
    T_CHAR_COUNT:1003, //Summerize text count sent to ChatGpt
    T_AD_P:1014, //Add  voice clone
    T_AUD_LEN:1006, //Adudio lenght received after converstion
    T_AD_AI:1017, //AI  voice clone 
    T_UP_IMG:1018, //Upload Image
    T_UP_VID:1019 //Upload VIdeo
}    