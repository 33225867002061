import React, { useState, useEffect, useCallback } from "react";
import DropFile from "./DropFile";
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";

const SyncModal = ({ closeModal, mainfileId, title, onCompleteAction }) => {
  const [syncBlockStatus, setSyncBlockStatus] = useState(1);
  const [mainItemDetail, setMainItemDetail] = useState([]);
  const [newItemDetail, setNewItemDetail] = useState([]);
  const [dropIndex, setDropIndex] = useState(null);
  const [replaceFlag, setReplaceFlag] = useState(false);
  const [stateStack, setStateStack] = useState([]);
  const [newfileId, setNewfileId] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [addedCount, setAddedCount] = useState(0);
  const [replacedCount, setReplacedCount] = useState(0);
  const [showTitle, setshowTitlet] = useState(title);
  const [replacements, setReplacements] = useState([]);
  const [slideInput, setslideInput] = useState('');
  const [isReplaceAllEnabled, setIsReplaceAllEnabled] = useState(false);

  const handleSlideInputChange = (event) => {
   setslideInput(event.target.value);
  };

  const importGslide = async () => {
   if (slideInput) {
     try {
       const formData = new FormData();
       let userId=localStorage.getItem("userId")
       //formdata.append("file", files[0].file);
       formData.append("userId", userId);
       console.log("slidesUrl",slideInput)
       formData.append("slidesUrl", slideInput);
       formData.append("isImported", 1);
       formData.append("original_fileId", mainfileId);
       const headers={
         "Content-Type": "application/json",
         }
         handleFileUpload();
       const response = await callAPI(apiUrls.SLIDEDOWNLOAD, {}, "POST",formData,{},headers);
        console.log(response)
         if (response.status == 200) {
          handleComplete(response.data.id,response.data.title); 
           console.log('Slide Imorted successfully!');
         } else {
           console.error('Slide Imorted failed.');
           // Handle failure
         }
     } catch (error) {
       console.error('Error occurred while uploading the file:', error);
       // Handle error
     }
   }
 };

  const handleFileUpload = () => {
    setSyncBlockStatus(2);
  };

  const completeAction = () => {
    // Your logic here
    console.log("ddddddvvv")
    onCompleteAction();
  };
  const handleComplete = (fileId) => {
    setNewfileId(fileId);
    setSyncBlockStatus(3);
  }

  const handleCloseModal = () => {
    closeModal()
  };

  const handleUndo = useCallback(() => {
    if (stateStack.length > 0) {
      const prevState = stateStack[stateStack.length - 1];
      setStateStack(stateStack.slice(0, -1));
      setMainItemDetail(prevState.mainItemDetail);
      setNewItemDetail(prevState.newItemDetail);
      setAddedCount(prevState.addedCount);
      setReplacedCount(prevState.replacedCount);
    }
  }, [stateStack]);

  useEffect(() => {
    const fetchItemDetail = async () => {
      if (mainfileId && newfileId) {
        try {
          const response = await callAPI(apiUrls.GETPPTDATA, { fileId: mainfileId }, 'GET');
          setMainItemDetail(response.data);

          const newResponse = await callAPI(apiUrls.GETPPTDATA, { fileId: newfileId }, 'GET');
          setNewItemDetail(newResponse.data);
          console.log("rrrrrrrrrrrrrrrrrrr",response.data.length, newResponse.data.length)
          if (response.data.length === newResponse.data.length) {
            setIsReplaceAllEnabled(true);
          } else {
            setIsReplaceAllEnabled(false);
          }
        } catch (error) {
          console.error('Failed to fetch item details:', error);
          setIsReplaceAllEnabled(false)
        }
      }
    };

    fetchItemDetail();
  }, [mainfileId, newfileId]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === 'z') {
        handleUndo();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleUndo]);

  const pushStateToStack = () => {
    setStateStack((prevStateStack) => [
      ...prevStateStack,
      {
        mainItemDetail: [...mainItemDetail],
        newItemDetail: [...newItemDetail],
        addedCount,
        replacedCount,
      }
    ]);
  };

  const handleDragStart = (e, index, source) => {
    if (source === 'newItemDetail' && newItemDetail[index].grayedOut) {
      e.preventDefault();
      return;
    }
    e.dataTransfer.setData('index', index);
    e.dataTransfer.setData('source', source);
  };

  const handleDrop = (e, destination) => {
    e.preventDefault();
    const index = e.dataTransfer.getData('index');
    const source = e.dataTransfer.getData('source');

    if (source === 'mainItemDetail' && destination === 'mainItemDetail') {
      pushStateToStack();
      const draggedItem = mainItemDetail[index];
      const newMainItemDetail = [...mainItemDetail];
      newMainItemDetail.splice(index, 1); // Remove the dragged item from the original position

      if (dropIndex !== null) {
        newMainItemDetail.splice(dropIndex, 0, draggedItem);
      } else {
        newMainItemDetail.push(draggedItem);
      }

      setMainItemDetail(newMainItemDetail);
    } else if (source === 'newItemDetail' && destination === 'mainItemDetail') {
      pushStateToStack();
      const item = newItemDetail[index];
      const newMainItemDetail = [...mainItemDetail];

      if (dropIndex !== null) {
        if (replaceFlag) {
          newMainItemDetail[dropIndex] = item;
          setReplacedCount((prevCount) => prevCount + 1);
          setReplacements((prevReplacements) => [
            ...prevReplacements,
            { replaced: mainItemDetail[dropIndex], replacer: item },
          ]);
        } else {
          newMainItemDetail.splice(dropIndex, 0, item);
          setAddedCount((prevCount) => prevCount + 1);
        }
      } else {
        newMainItemDetail.push(item);
        setAddedCount((prevCount) => prevCount + 1);
      }

      setMainItemDetail(newMainItemDetail);
      const newNewItemDetail = newItemDetail.map((item, i) =>
        i === parseInt(index) ? { ...item, grayedOut: true } : item
      );
      setNewItemDetail(newNewItemDetail);
    }

    setDropIndex(null);
    setReplaceFlag(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (index) => {
    setDropIndex(index);
    setReplaceFlag(true);
  };

  const handleDragLeave = () => {
    setReplaceFlag(false);
  };

  const updateSequence = async (action) => {
    // const updatedData = mainItemDetail.map((item, index) => ({
    //   ...item,
    //   sequence: index,
    // }));
    const updatedData = mainItemDetail.map((item, index) => ({
      imageId: item.id,
      sequence: index,
    }));

    try {
      console.log("updatedData", updatedData)
//      await callAPI(apiUrls.UPDATEPPTDATA, {updatedData,mainfileId, newfileId, }, 'POST');
      const response = await callAPI(apiUrls.UPDATEPPTDATA, {}, "POST",{updatedData,mainfileId, newfileId, action,replacements });
      onCompleteAction();
      setSyncBlockStatus(4);
      console.log('Sequence updated successfully');
    } catch (error) {
      console.error('Failed to update sequence:', error);
    }
  };

  const handleNextClick = async (action) => {
    await updateSequence(action);
    console.log('Next button clicked');
  };

  const handleDropFileError = (error) => {
    setErrorMessage(error);
  };

  const preloadImage = (src, retries = 5, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const load = (attempt) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = () => {
          if (attempt < retries) {
            setTimeout(() => load(attempt + 1), delay);
          } else {
            reject(new Error('Image failed to load after multiple attempts'));
          }
        };
      };
      load(0);
    });
  };

  const preloadNewItemImages = async () => {
    try {
      await Promise.all(newItemDetail.map((item) => preloadImage(item.value)));
      console.log('All images preloaded successfully');
    } catch (error) {
      console.error('Failed to preload images:', error);
    }
  };

  useEffect(() => {
    if (newItemDetail.length > 0) {
      preloadNewItemImages();
    }
  }, [newItemDetail]);

  return (
    <>
      {syncBlockStatus === 1 ? (
        <div className="model_box_child addNewDeckPop UpdateDeckPop" id="UpdateDeckPop" style={{ width: "752px", display: "block!important" }}>
          <h4>
            <span className="icon">
              <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.2708 17.6907C26.9004 21.1706 24.9287 24.4316 21.666 26.3152C16.2454 29.4449 9.31402 27.5876 6.18441 22.167L5.85107 21.5896M4.72834 15.3093C5.09874 11.8295 7.07046 8.56851 10.3331 6.68482C15.7538 3.55521 22.6851 5.41246 25.8147 10.8331L26.1481 11.4105M4.65771 24.5881L5.63378 20.9453L9.27652 21.9214M22.7234 11.0787L26.3661 12.0547L27.3422 8.41201" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            <span>Update deck</span>
            <span className="close_model" onClick={handleCloseModal}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 6L6 18M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </h4>
          <div className="contentSection">
            <div className="fSteps mb-24 mt-48">
              <ul>
                <li className="finished">
                  <span>Import new deck</span>
                  <i className="checkIocn">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 3L4.5 8.5L2 6" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </i>
                </li>
                <li>
                  <span>Make changes</span>
                </li>
                <li>
                  <span>Update Deck</span>
                </li>
              </ul>
            </div>

            <DropFile
              onFileUpload={handleFileUpload}
              onComplete={handleComplete}
              closeModal={closeModal}
              onError={handleDropFileError}
              isImported={true}
              originalFileId={mainfileId}
            />

            <span className="or flex jcCenter mt-16 mb-24">or</span>
            <div className="uploadForm">
              <p>Add google slides presentation</p>
              <div className="flex alignCenter light">
                <input type="text" value={slideInput} onChange={handleSlideInputChange} name="" id="" placeholder="Paste google slides link here" className="mr-8 fullW " />
              </div>
              <span>Make sure your presentation can be viewed by ‘Anyone with link’.</span>
            </div>
          </div>
          <div className="flex mt-12 actionButton">
            <button className="btn InversePrimary"  onClick={importGslide} >Next</button>
          </div>
        </div>
      ) : syncBlockStatus === 2 ? (
        <div className="model_box_child importUpdatePop" id="importUpdatePop" style={{ width: "752px", display: "block!important" }}>
          <div className="contentSection">
            <div className="progressBar mb-24">
              <div className="activeBar" style={{ width: "10%" }}></div>
            </div>
            <h5>Importing presentation...</h5>
            <p>This window will auto-close once presentation has finished importing</p>
          </div>
        </div>
      ) : syncBlockStatus === 3 ? (
        <div className="model_box_child makeChangesPop makeChangesPop" id="makeChangesPop" style={{ width: "752px", display: "block!important" }}>
          <h4>
            <span className="icon">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.2703 17.1907C26.8999 20.6706 24.9282 23.9316 21.6655 25.8152C16.2449 28.9449 9.31353 27.0876 6.18392 21.667L5.85058 21.0896M4.72785 14.8093C5.09825 11.3295 7.06997 8.06851 10.3326 6.18482C15.7533 3.05521 22.6846 4.91246 25.8142 10.3331L26.1476 10.9105M4.65723 24.0881L5.63329 20.4453L9.27603 21.4214M22.7229 10.5787L26.3656 11.5547L27.3417 7.91201" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            <span>Make changes</span>
            <span className="close_model" onClick={handleCloseModal}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 6L6 18M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </h4>
          <div className="contentSection">
            <div className="fSteps mb-24 mt-48">
              <ul>
                <li className="finished">
                  <span>Import new deck</span>
                  <i className="checkIocn">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 3L4.5 8.5L2 6" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </i>
                </li>
                <li className="active">
                  <span>Make changes</span>
                </li>
                <li>
                  <span>Update Deck</span>
                </li>
              </ul>
            </div>
            <div className="infoTxt">Make changes to your deck by dragging slides from your imported deck</div>
            <div className="pictureThumbslidRow">
              <ul className="scrollBarC sm" onDrop={(e) => handleDrop(e, 'newItemDetail')} onDragOver={handleDragOver}>
                {newItemDetail.map((item, index) => (
                  <li
                    key={index}
                    draggable={!item.grayedOut}
                    onDragStart={(e) => handleDragStart(e, index, 'newItemDetail')}
                    style={{ opacity: item.grayedOut ? 0.5 : 1, pointerEvents: item.grayedOut ? 'none' : 'auto' }}
                  >
                    <img src={item.value} alt="" onError={(e) => e.target.src = item.value} />
                  </li>
                ))}
              </ul>
              <div className="picUploadedStatus mt-4">
                <p>{`Imported Deck • ${newItemDetail.length} slides`}</p>
              </div>
            </div>
            <div className="pictureThumbslidRow">
              <ul className="scrollBarC" onDrop={(e) => handleDrop(e, 'mainItemDetail')} onDragOver={handleDragOver}>
                {mainItemDetail.map((item, index) => (
                  <li
                    key={index}
                    draggable
                    onDragStart={(e) => handleDragStart(e, index, 'mainItemDetail')}
                    onDragEnter={() => handleDragEnter(index)}
                    onDragLeave={handleDragLeave}
                  >
                    <img src={item.value} alt="" />
                  </li>
                ))}
              </ul>
              <div className="picUploadedStatus mt-4">
                <p> {showTitle} • {mainItemDetail.length} slides</p>
                <span className="active">Updated</span>
                <p>{`${replacedCount} slides updated, ${addedCount} slides added`}</p>
              </div>
            </div>
          </div>
          <div className="flex mt-24 actionButton jcSpacebetween">
            <button className="btn Secondary" onClick={() => handleNextClick('replace')}    disabled={!isReplaceAllEnabled}  style={{
          backgroundColor: isReplaceAllEnabled ? '' : '#d3d3d3',
          cursor: isReplaceAllEnabled ? 'pointer' : 'not-allowed',
          color: isReplaceAllEnabled ? '' : '#666',
          display:isReplaceAllEnabled?"":"none"
        }}>Repace All</button>
        <div></div>
            <button className="btn InversePrimary" onClick={() => handleNextClick('next')}>Next</button>
          </div>
        </div>
        ) : syncBlockStatus === 4 ? (
          <div class="model_box_child generatedNotesPop" id="generatedNotesPop" style={{width: "752px", height: "400px", display: "block!important"}}>
          <div class="contentSection">
             <div class="loaderIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="81" viewBox="0 0 80 81" fill="none">
                  <path d="M25.0001 40.5L35.0001 50.5L55.0001 30.5M73.3334 40.5C73.3334 58.9095 58.4096 73.8333 40.0001 73.8333C21.5906 73.8333 6.66675 58.9095 6.66675 40.5C6.66675 22.0905 21.5906 7.16666 40.0001 7.16666C58.4096 7.16666 73.3334 22.0905 73.3334 40.5Z" stroke="#067647" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
             </div>
             <h5 class="mt-8">Your deck was updated successfully</h5>
             <a href="javascript:void(0);" class="btn Secondary mt-16" onClick={handleCloseModal}>Close This Window</a>
          </div>
       </div>
      ) : null}
    </>
  );
};

export default SyncModal;
