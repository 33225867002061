import React, { useState, useEffect, useRef } from 'react';
import { checkIstrialPlan } from "../utils/utilFn";

const Header = ({handleDeckHeader}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [initial, setInitial] = useState("U");
  const [trialMessage, setTrialMessage] = useState("");
  
  const userNavRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleClickOutside = (event) => {
    if (userNavRef.current && !userNavRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };
 
  const calculateRemainingDays = (expiry) => {
    const expiryDate = new Date(expiry); // Parse the expiry date
    const currentTime = new Date(); // Get the current time

    // Calculate the difference in milliseconds
    const remainingTimeInMs = expiryDate - currentTime;

    // Convert milliseconds to days (1 day = 86400000 milliseconds)
    const remainingDate = Math.ceil(remainingTimeInMs / (1000 * 60 * 60 * 24)); 

    // Check if the trial is over
    if (remainingDate <= 0) {
        return "";
    }

    // Generate the message
    return (
      <>
        Your free trial ends in {remainingDate} day{remainingDate > 1 ? "s" : ""}. 
        <a href="javascript:void(0);" onClick={() => handleDeckHeader('Payment')}>
          Upgrade Now!
        </a>
      </>
    );
};

  useEffect(() => {
    const fetchData = async () => {
      const trialPlan = await checkIstrialPlan();
      if (trialPlan) {
        const msg = calculateRemainingDays(trialPlan);
        setTrialMessage(msg);
      } else {
        setTrialMessage("");
      }
  
      let initial = localStorage.getItem("initial");
      setInitial(initial);
    };
  
    fetchData();
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="headerWrap">
      <div className="header">
        <div className="deckLogo">
          {/* Logo or branding */}
 <svg width="97" height="30" viewBox="0 0 216 67" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1275_10371)">
<path d="M49.0598 10.33C46.2898 6.39996 42.6598 3.69996 38.1798 2.21996C35.5498 1.34996 32.2498 0.889956 28.2898 0.829956H0.759766V64.74H28.2898C37.9398 64.74 45.0798 60.77 49.7098 52.82C53.1198 46.92 54.8298 39.73 54.8298 31.23C54.8298 27.88 54.4198 24.35 53.6198 20.65C52.8098 16.95 51.2898 13.51 49.0698 10.33H49.0598ZM39.2198 44.32C36.7298 50.53 32.3398 53.64 26.0398 53.64H13.7298V11.93H26.0398C32.1098 11.93 36.1698 13.66 38.2198 17.13C40.2398 20.63 41.2498 25.63 41.2498 32.13C41.2498 36.84 40.5698 40.9 39.2098 44.31L39.2198 44.32Z" fill="white"/>
<path d="M98.7397 25.41C96.6897 22.23 94.0897 19.9 90.9397 18.43C87.7897 16.96 84.2497 16.22 80.3197 16.22C73.6997 16.22 68.3197 18.4 64.1697 22.77C60.0197 27.13 57.9497 33.41 57.9497 41.59C57.9497 50.32 60.2497 56.62 64.8397 60.49C69.4397 64.36 74.7397 66.3 80.7497 66.3C88.0297 66.3 93.6997 64 97.7497 59.41C100.35 56.52 101.81 53.67 102.13 50.87H89.5097C88.8397 52.26 88.0797 53.34 87.2097 54.12C85.6197 55.57 83.5497 56.29 81.0097 56.29C78.6097 56.29 76.5597 55.7 74.8497 54.51C72.0497 52.6 70.5597 49.28 70.3797 44.54H102.72C102.78 40.46 102.65 37.34 102.33 35.18C101.78 31.48 100.58 28.23 98.7297 25.42L98.7397 25.41ZM70.6897 36.38C71.0897 33.32 72.0797 30.89 73.6597 29.1C75.2297 27.31 77.4497 26.41 80.3197 26.41C82.9497 26.41 85.1497 27.26 86.9297 28.95C88.7097 30.64 89.6997 33.12 89.8997 36.39H70.6897V36.38Z" fill="white"/>
<path d="M128.82 26.8C131.74 26.8 133.89 27.75 135.28 29.66C136.23 31.08 136.83 32.67 137.06 34.43H149.72C149.03 27.7 146.79 23 143.02 20.34C139.25 17.68 134.4 16.35 128.47 16.35C121.5 16.35 116.04 18.6 112.08 23.11C108.12 27.62 106.14 33.93 106.14 42.06C106.14 49.26 107.93 55.11 111.52 59.62C115.1 64.13 120.7 66.38 128.3 66.38C135.9 66.38 141.64 63.68 145.51 58.27C147.94 54.92 149.3 51.36 149.59 47.6H136.97C136.71 50.09 135.97 52.11 134.76 53.67C133.55 55.23 131.49 56.01 128.6 56.01C124.52 56.01 121.75 54.04 120.28 50.11C119.47 48 119.07 45.21 119.07 41.74C119.07 38.1 119.47 35.18 120.28 32.98C121.81 28.85 124.66 26.78 128.82 26.78V26.8Z" fill="white"/>
<path d="M180.41 35.7799L196.93 17.6999H181.62L166.23 35.4699V1.04993H154.09V64.7399H166.23V49.8699L171.35 44.5299L182.67 64.7399H197.58L180.41 35.7799Z" fill="white"/>
<path d="M198.15 2.02995H200.94V9.37995H202.32V2.02995H205.11V0.829956H198.15V2.02995Z" fill="white"/>
<path d="M213.64 0.829956L211.67 5.98995C211.52 6.41995 211.39 6.80995 211.27 7.17995C211.19 7.42995 211.11 7.69995 211.03 7.95995V7.93996C210.99 7.80996 210.95 7.67996 210.91 7.54996C210.82 7.24996 210.73 6.97996 210.64 6.72996C210.55 6.47996 210.47 6.22995 210.37 5.98995L208.37 0.839951H206.48V9.37995H207.75V4.67995C207.75 4.33995 207.75 4.00995 207.75 3.66995C207.75 3.32995 207.74 2.98995 207.72 2.63995C207.72 2.47995 207.72 2.32996 207.7 2.15996C207.7 2.14996 207.7 2.13995 207.7 2.12995C207.7 2.14995 207.71 2.17995 207.72 2.19995C207.78 2.37995 207.84 2.54996 207.9 2.72996C207.98 2.95996 208.05 3.18996 208.13 3.39996C208.2 3.60996 208.28 3.81996 208.36 4.03996C208.44 4.25996 208.52 4.46996 208.6 4.68996L210.43 9.38995H211.64L213.45 4.68996C213.57 4.35996 213.68 4.03996 213.79 3.72996C213.9 3.41996 214.01 3.09995 214.12 2.77995C214.19 2.57995 214.26 2.34995 214.33 2.12995C214.32 2.50995 214.31 2.87996 214.3 3.22996C214.29 3.69996 214.28 4.17996 214.27 4.68996V9.38995H215.54V0.849953H213.65L213.64 0.829956Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1275_10371">
<rect width="216" height="67" fill="white"/>
</clipPath>
</defs>
</svg>

        </div>
        <div> {trialMessage} </div>
        <div style={{display:"flex", gap:"10px"}}>
          <div style={{paddingTop:"5px"}} >
          <a href="https://blog.deck.work" target='new'>
          <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1200 1200" fill="gray" width="30" height="30" >
  <path d="M600,106c-272.4,0-494,221.6-494,494s221.6,494,494,494,494-221.6,494-494S872.4,106,600,106ZM600,992.4c-216.4,0-392.4-176-392.4-392.4s192.8-340.6,239.5-360.3c48.4-20.4,99.8-30.8,152.9-30.8s103.9,10.3,152.1,30.8c46.6,19.7,88.4,47.9,124.5,83.9,36,35.9,64.4,77.8,84.3,124.3,20.6,48.2,31.2,99.4,31.5,152.1,0,105-40.8,203.6-114.8,277.6s-172.6,114.8-277.6,114.8h0Z"/>
  <path d="M600,313.4c-48.7,0-94.7,19-129.6,53.5-34.7,34.3-54.6,80.2-56,129v.3c0,28.4,23.1,51.5,51.5,51.5s51.5-23.1,51.5-51.5,8.5-41.7,24-57.2,35.8-24,57.2-24,41.7,8.5,57.2,24,24,35.8,24,57.2v.5c1.1,22.4-13.3,42.3-35.8,49.4-31.3,9.5-58.2,28.5-77.9,54.9-19.7,26.6-30.1,58.3-30.1,91.5s23.1,51.5,51.5,51.5h6.8l.2-.5c10.8-1.6,21.1-6.8,29.1-14.9,9.8-9.8,15.4-22.9,15.4-36.1v-.5c-1.1-22.4,13.3-42.3,35.8-49.4,31.3-9.5,58.2-28.5,77.9-54.9,19.7-26.6,30.1-58.3,30.1-91.5,0-100.8-82-182.9-182.9-182.9h0Z"/>
  <path d="M587.6,780.1c-33,0-59.8,26.8-59.8,59.8s26.8,59.8,59.8,59.8,59.8-26.8,59.8-59.8-26.8-59.8-59.8-59.8Z"/>
</svg>
</a>
</div>
        <div className={isMenuOpen ? "userNav open" : "userNav"}  ref={userNavRef} onClick={toggleMenu}>
          {/* User navigation */}
          
          <i className="userAvtar" >{initial}</i>
          <ul className="dropMenu" style={{ display: isMenuOpen ? 'block' : 'none' }} >
            {/* User dropdown menu items */}
            <li>
                    <a href="javascript:void(0);" onClick={() => handleDeckHeader('Account')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                          <path d="M3 8L15 8M15 8C15 9.65686 16.3431 11 18 11C19.6569 11 21 9.65685 21 8C21 6.34315 19.6569 5 18 5C16.3431 5 15 6.34315 15 8ZM9 16L21 16M9 16C9 17.6569 7.65685 19 6 19C4.34315 19 3 17.6569 3 16C3 14.3431 4.34315 13 6 13C7.65685 13 9 14.3431 9 16Z" stroke="#A3A3A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Account Settings</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" class="active open_modelgry" onClick={() => handleDeckHeader('Payment')}>
                         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <g clip-path="url(#clip0_442_1752)">
                            <path d="M10.0003 9.99995V5.99995M10.0003 5.99995H6.00029M10.0003 5.99995L6.00029 9.99988M14.6668 7.99992C14.6668 11.6818 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99992C1.3335 4.31802 4.31826 1.33325 8.00016 1.33325C11.6821 1.33325 14.6668 4.31802 14.6668 7.99992Z" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_442_1752">
                              <rect width="16" height="16" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                        <span>Subscription</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" onClick={() => handleDeckHeader('logout')}>
                         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <path d="M10.6667 11.3333L14 8M14 8L10.6667 4.66667M14 8H6M8 11.3333C8 11.9533 8 12.2633 7.93185 12.5176C7.74692 13.2078 7.20782 13.7469 6.51764 13.9319C6.26331 14 5.95332 14 5.33333 14H5C4.06812 14 3.60218 14 3.23463 13.8478C2.74458 13.6448 2.35523 13.2554 2.15224 12.7654C2 12.3978 2 11.9319 2 11V5C2 4.06812 2 3.60218 2.15224 3.23463C2.35523 2.74458 2.74458 2.35523 3.23463 2.15224C3.60218 2 4.06812 2 5 2H5.33333C5.95332 2 6.26331 2 6.51764 2.06815C7.20782 2.25308 7.74692 2.79218 7.93185 3.48236C8 3.7367 8 4.04669 8 4.66667" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Sign out</span>
                    </a>
                </li>
          </ul>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Header;