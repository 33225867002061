import React, { useState, useEffect } from 'react';
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import './StripeButton.css'; 
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {defaultConfig} from '../config';
import countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import { color } from 'chart.js/helpers';
countries.registerLocale(en);
// Initialize Stripe
const stripePromise = loadStripe(defaultConfig.stripePublishKey); // Replace with your actual Stripe publishable key

const CheckoutForm = ({ clientSecret, planId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('');
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: 'white',
        backgroundColor: 'black',
        '::placeholder': {
          color: 'white',
        },
        border: '1px solid white',
        width:'500px'
      },
      invalid: {
        color: '#fa755a',
      },
    },
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
     if(name.trim() === "") {
      console.log("sasANAMEEEE")
      setErrorMessage("Please Enter Name");
      return
    }
    setIsSubmitting(true);
    setErrorMessage('');

    const cardElement = elements.getElement(CardElement);

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name, // Cardholder name
        },
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      setPaymentSuccessful(true);
      console.log('Payment succeeded:', paymentIntent);
      // Handle post-payment success (e.g., update user plan)
      const response = await callAPI(apiUrls.UPDATEPAYMETINTENT, {}, "POST",{ paymentIntentId: paymentIntent.id, planId:planId });
      
    }
    setIsSubmitting(false);
  };

  return (
    !isPaymentSuccessful ? (
    <form onSubmit={handleSubmit}>
      <div class="row gap24" style={{marginBottom:"10px"}}>
                        <div class="col flex flexColum w100">
                           <label class="mb-8" style={{color:"white"}}>Name on card</label>
                           <input type="text" value={name}  name="" placeholder="Name on card" class="w100" onChange={(e) => setName(e.target.value)}/>
                        </div>
                        
                     </div> 
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <CardElement  options={CARD_ELEMENT_OPTIONS} />
      {/* <button type="submit" disabled={!stripe}> */}
      <button type="submit" disabled={!stripe || isSubmitting || isPaymentSuccessful}>
      {isPaymentSuccessful ? 'Payment Successful' : 'Pay'}
      </button>
      </div>
      {errorMessage && <div style={{color:"red"}}>{errorMessage}</div>}
      {isPaymentSuccessful && <div style={{color:"teal", width:"100%", textAlign:"center", alignItems: 'center',}}><b>Thank you for your payment. We will email you a copy of your receipt.</b></div>}
    </form>
     ):(
      <div style={{color:"teal", width:"100%", textAlign:"center", alignItems: 'center',}}><b>Thank you for your payment. We will email you a copy of your receipt.</b></div>
    )
  );
};

const PaymentForm = ({ selectedPaymentPlan, setActiveNavItem,setSelectedPaymentPlan}) => {

    const [clientSecret, setClientSecret] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [proratedAmt, setProratedAmt] = useState('');
    const [isAddressSubmitted, setIsAddressSubmitted] = useState(false);
    const [formData, setFormData] = useState({
      streetAddress: "",
      streetAddress2: "",
      city: "",
      state: "",
      country: "",
      zipcode:"",
    });
    const [originalData, setOriginalData] = useState({});
    const [isChanged, setIsChanged] = useState(false);
    const [countryOptions, setCountryOptions] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [breakUp, setBreakUp] = useState({});
    const [isDirect, setIsDirect] = useState(false);
    

    
    const handleUpgradeClick = async () => {
      const amount = 50; // Example amount in cents ($10)
      console.log("jjjjj")
      try {
        const formData = new FormData();
         formData.append("amount", amount);


         const headers={
          "Content-Type": "application/json",
          }
         
        const response = await callAPI(apiUrls.CREATEPAYMETINTENT, {}, 'POST', formData, {}, headers);
        console.log("response.clientSecret", response.data.clientSecret)
        
        setClientSecret(response.data.clientSecret);
      } catch (error) {
        console.error('Error creating payment intent:', error);
      }
    };

    const handleCreateSubscription = async () => {
      try {
        const formSubData = new FormData();
        formSubData.append("planId", selectedPaymentPlan.pid);
         const headers={
          "Content-Type": "application/json",
          }
        
        const response = await callAPI(apiUrls.CREATESUBSCRIPTION, {}, 'POST', formSubData, {}, headers);
        console.log(response)
        if(response.data.clientSecret) {
          setClientSecret(response.data.clientSecret);
          setBreakUp(response.data.breakup)
        }  else if(response.data.isDirect == 1) {
            setIsDirect(response.data.message)
            setBreakUp(response.data.breakup)
        }  else {
          console.log(response.data.error)
          setClientSecret("");
          setErrorMessage(response.data.error);
          setIsAddressSubmitted(false)
          setIsDirect(false)
        
        } 
        
        if(response.data.amountDue) {
          setProratedAmt(response.data.amountDue);
        } else {
          setProratedAmt("");
        }
       // setPlanId(selectedPaymentPlan);
      } catch (error) {
        console.error('Error creating subscription:', error);
      }
    };

    useEffect(() => {
      if (isAddressSubmitted && selectedPaymentPlan) {
        handleCreateSubscription();
      }
    }, [isAddressSubmitted, selectedPaymentPlan]);

    useEffect(() => {
          // Populate country options
    const countryList = Object.entries(countries.getNames("en")).map(([code, name]) => ({
      label: name,
      value: code,
    }));
    setCountryOptions(countryList);
      const fetchBillingDetails = async () => {
        try {
          const response = await callAPI(apiUrls.GETADDRESS, {}, "GET");;
          const data = response.data.address;
          
          setFormData({
            streetAddress: data.streetAddress || "",
            streetAddress2: data.streetAddress2 || "",
            city: data.city || "",
            state: data.state || "",
            country: data.country || "",
            zipcode: data.zipcode || "",
          });
          setOriginalData(data);
        } catch (error) {
          console.error("Failed to fetch billing details", error);
        }
      };
  
      fetchBillingDetails();
    }, []);

    
      // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      setIsChanged(
        Object.keys(updatedData).some(
          (key) => updatedData[key] !== originalData[key]
        )
      );
      return updatedData;
    });
  };
 
  const handleAddressSubmit = async () => {
    try {
      // Assuming formData contains the relevant address fields
      const payload = { ...formData, isChanged }; // Ensure formData has the correct fields
      // const response = await fetch("/api/updateAddress", {
      //   method: "POST", // Update to match the address update endpoint
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify(payload),
      // });
      const headers={
        "Content-Type": "application/json",
        }
      
      const response = await callAPI(apiUrls.UPDATEADDRESS, {}, 'POST', JSON.stringify(payload), {}, headers);

      if (response.data.profileId) {
        setIsAddressSubmitted(true); 
        console.log("Address updated successfully");
      } else {
        console.error("Failed to update address", response.data.message);
      }
    } catch (error) {
      console.error("Failed to update address", error);
    }
  };

  
    return (
        <div class="dashContent">
          <div class="pageNavigation mb-24">
   <ul>
      <li    onClick={() => {
              setSelectedPaymentPlan(false);
              setActiveNavItem('Payment');
            }}>
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>
         <span>
         <a href="javascript:void(0);" >Go back</a>
         </span>
      </li>
   </ul>
</div>
          <h1>{isAddressSubmitted?"Review Order":"Billing Details"}</h1>
          <div class="dForm mt-32">
            <div class="wrapper">
            {isAddressSubmitted ? (
              <>
            
            <section class="infBox viewPlan">
                <div class="dbBox mt-12 AnalyticsContent">
                  <div class="row gap8 AnalyListhView">
                  <ul style={{width:"100%"}}>
                  <li style={{borderBottom:"solid 1px white"}}>
                  <span>
                  <svg width="97" height="30" viewBox="0 0 216 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1275_10371)">
                      <path d="M49.0598 10.33C46.2898 6.39996 42.6598 3.69996 38.1798 2.21996C35.5498 1.34996 32.2498 0.889956 28.2898 0.829956H0.759766V64.74H28.2898C37.9398 64.74 45.0798 60.77 49.7098 52.82C53.1198 46.92 54.8298 39.73 54.8298 31.23C54.8298 27.88 54.4198 24.35 53.6198 20.65C52.8098 16.95 51.2898 13.51 49.0698 10.33H49.0598ZM39.2198 44.32C36.7298 50.53 32.3398 53.64 26.0398 53.64H13.7298V11.93H26.0398C32.1098 11.93 36.1698 13.66 38.2198 17.13C40.2398 20.63 41.2498 25.63 41.2498 32.13C41.2498 36.84 40.5698 40.9 39.2098 44.31L39.2198 44.32Z" fill="white"/>
                      <path d="M98.7397 25.41C96.6897 22.23 94.0897 19.9 90.9397 18.43C87.7897 16.96 84.2497 16.22 80.3197 16.22C73.6997 16.22 68.3197 18.4 64.1697 22.77C60.0197 27.13 57.9497 33.41 57.9497 41.59C57.9497 50.32 60.2497 56.62 64.8397 60.49C69.4397 64.36 74.7397 66.3 80.7497 66.3C88.0297 66.3 93.6997 64 97.7497 59.41C100.35 56.52 101.81 53.67 102.13 50.87H89.5097C88.8397 52.26 88.0797 53.34 87.2097 54.12C85.6197 55.57 83.5497 56.29 81.0097 56.29C78.6097 56.29 76.5597 55.7 74.8497 54.51C72.0497 52.6 70.5597 49.28 70.3797 44.54H102.72C102.78 40.46 102.65 37.34 102.33 35.18C101.78 31.48 100.58 28.23 98.7297 25.42L98.7397 25.41ZM70.6897 36.38C71.0897 33.32 72.0797 30.89 73.6597 29.1C75.2297 27.31 77.4497 26.41 80.3197 26.41C82.9497 26.41 85.1497 27.26 86.9297 28.95C88.7097 30.64 89.6997 33.12 89.8997 36.39H70.6897V36.38Z" fill="white"/>
                      <path d="M128.82 26.8C131.74 26.8 133.89 27.75 135.28 29.66C136.23 31.08 136.83 32.67 137.06 34.43H149.72C149.03 27.7 146.79 23 143.02 20.34C139.25 17.68 134.4 16.35 128.47 16.35C121.5 16.35 116.04 18.6 112.08 23.11C108.12 27.62 106.14 33.93 106.14 42.06C106.14 49.26 107.93 55.11 111.52 59.62C115.1 64.13 120.7 66.38 128.3 66.38C135.9 66.38 141.64 63.68 145.51 58.27C147.94 54.92 149.3 51.36 149.59 47.6H136.97C136.71 50.09 135.97 52.11 134.76 53.67C133.55 55.23 131.49 56.01 128.6 56.01C124.52 56.01 121.75 54.04 120.28 50.11C119.47 48 119.07 45.21 119.07 41.74C119.07 38.1 119.47 35.18 120.28 32.98C121.81 28.85 124.66 26.78 128.82 26.78V26.8Z" fill="white"/>
                      <path d="M180.41 35.7799L196.93 17.6999H181.62L166.23 35.4699V1.04993H154.09V64.7399H166.23V49.8699L171.35 44.5299L182.67 64.7399H197.58L180.41 35.7799Z" fill="white"/>
                      <path d="M198.15 2.02995H200.94V9.37995H202.32V2.02995H205.11V0.829956H198.15V2.02995Z" fill="white"/>
                      <path d="M213.64 0.829956L211.67 5.98995C211.52 6.41995 211.39 6.80995 211.27 7.17995C211.19 7.42995 211.11 7.69995 211.03 7.95995V7.93996C210.99 7.80996 210.95 7.67996 210.91 7.54996C210.82 7.24996 210.73 6.97996 210.64 6.72996C210.55 6.47996 210.47 6.22995 210.37 5.98995L208.37 0.839951H206.48V9.37995H207.75V4.67995C207.75 4.33995 207.75 4.00995 207.75 3.66995C207.75 3.32995 207.74 2.98995 207.72 2.63995C207.72 2.47995 207.72 2.32996 207.7 2.15996C207.7 2.14996 207.7 2.13995 207.7 2.12995C207.7 2.14995 207.71 2.17995 207.72 2.19995C207.78 2.37995 207.84 2.54996 207.9 2.72996C207.98 2.95996 208.05 3.18996 208.13 3.39996C208.2 3.60996 208.28 3.81996 208.36 4.03996C208.44 4.25996 208.52 4.46996 208.6 4.68996L210.43 9.38995H211.64L213.45 4.68996C213.57 4.35996 213.68 4.03996 213.79 3.72996C213.9 3.41996 214.01 3.09995 214.12 2.77995C214.19 2.57995 214.26 2.34995 214.33 2.12995C214.32 2.50995 214.31 2.87996 214.3 3.22996C214.29 3.69996 214.28 4.17996 214.27 4.68996V9.38995H215.54V0.849953H213.65L213.64 0.829956Z" fill="white"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1275_10371">
                      <rect width="216" height="67" fill="white"/>
                      </clipPath>
                      </defs>
                  </svg>
                  <button class="btn PrimaryOutline" style={{textAlign:"center", marginTop:"0px", background:"gray", color:"white", padding:"2px 5px"}}  >
                  {selectedPaymentPlan ? selectedPaymentPlan.title.split(' ').slice(1).join(' ') : ""}
                        </button>
                </span>
               
                        <span style={{color:"white"}}><b>${ JSON.parse(selectedPaymentPlan.description).price}</b></span>
                        </li>
                        
                        {breakUp.prorated &&
                        <li style={{borderBottom:"none"}}>
                           <span><b>Prorated Amount</b></span>
                          <span><b>${breakUp.prorated/100}</b></span>
                        </li>
                        }
                        {breakUp.refund &&
                        <li style={{borderBottom:"none"}}>
                           <span><b>Refund</b></span>
                          <span><b>-${breakUp.refund/100}</b></span>
                        </li>
                        }
                        {breakUp.subtotal &&
                        <li style={{borderBottom:"none"}}>
                          <span><b>Subtotal</b></span>
                          <span><b>${breakUp.subtotal/100}</b></span>
                        </li>
                        }
                        {breakUp.tax > 0 &&
                        <li style={{borderBottom:"none"}}>
                          <span><b>GST / HST</b></span>
                          <span><b>${breakUp.tax/100}</b></span>
                        </li>
                        }
                        {breakUp.total &&
                        <li style={{borderBottom:"none", borderTop:"solid 1px"}}>
                          <span><b>Due Today</b></span>
                          <span><b>${breakUp.total/100}</b></span>
                        </li>
                        }
                        
                        </ul>
                        
                     </div>
                  </div>
               </section>
            
            <section class="infBox viewPlan">
                  <h3>Payment Details</h3>
                  <script async src="https://js.stripe.com/v3/buy-button.js"></script>
                   <div class="dbBox mt-12">
                   {/* <div class="row gap24">
                        <div class="col flex flexColum w100">
                           <label class="mb-8">Name on card</label>
                           <input type="text" value={firstName}  name="" placeholder="Name on card" class="w100" onChange={handleFirstNameChange}/>
                        </div>
                     
                     </div> */}
                     
                  
                      <div class="row" style={{width:"100%"}}>

                   {clientSecret && (
            <Elements stripe={stripePromise}>
              <CheckoutForm clientSecret={clientSecret} planId={selectedPaymentPlan.pid}/>
            </Elements>
          )} </div>
          { isDirect &&
              <div style={{color:"teal", width:"100%", textAlign:"center", alignItems: 'center',}}><b>{isDirect}</b></div>
          }
                   </div>
         
                   
                </section>
                </>
) : (
              <section className="infBox viewPlan">
        
        <div className="dbBox mt-12">
          <div className="row gap24">
            <div className="col flex flexColum w100">
              <label className="mb-8">Street Address</label>
              <input
                type="text"
                name="streetAddress"
                value={formData.streetAddress}
                onChange={handleChange}
                placeholder="Street Address"
                className="w100"
              />
            </div>
          </div>
          <div className="row gap24">
            <div className="col flex flexColum w100">
              <label className="mb-8">Street Address 2 (Optional)</label>
              <input
                type="text"
                name="streetAddress2"
                value={formData.streetAddress2}
                onChange={handleChange}
                placeholder="Street Address 2"
                className="w100"
              />
            </div>
          </div>
          <div className="row gap24">
            <div className="col flex flexColum w100">
              <label className="mb-8">City</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="City"
                className="w100"
              />
            </div>
            <div className="col flex flexColum w100">
              <label className="mb-8">State</label>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                placeholder="State"
                className="w100"
              />
            </div>
          </div>
          <div className="row gap24">
            <div className="col flex flexColum w100">
              <label className="mb-8">Country</label>
              
              <select
          name="country"
          value={formData.country}
          onChange={handleChange}
          className="w100"
          style={{background:"transparent", color:"gray",height:"40px",border:"1px solid #d6d6d6",borderRadius:"10px",padding:"10px 12px", fontSize:"16px" }}
        >
          <option value="" disabled >Select Country</option>
          {countryOptions.map((country) => (
            <option key={country.value} value={country.value}>
              {country.label}
            </option>
          ))}
        </select>
            </div>
            <div className="col flex flexColum w100">
              <label className="mb-8">zipcode</label>
              <input
                type="text"
                name="zipcode"
                value={formData.zipcode}
                onChange={handleChange}
                placeholder="Zipcode"
                className="w100"
              />
            </div>
          </div>
          {errorMessage && <div style={{color:"red"}}>{errorMessage}</div>}
          
          <div class="actionButtons jcflexend w100">
          <div style={{color:"#00BFA5", textAlign:"right"}}> Your Plan: {selectedPaymentPlan ? selectedPaymentPlan.title: ""} (${ JSON.parse(selectedPaymentPlan.description).price})</div>
                  <button className="btn Primary" onClick={handleAddressSubmit}>Checkout</button>
              </div>
            </div>
       </section>

)}
                
        
        
             </div>
          </div>
      </div>

    );
};

export default PaymentForm;