import React, { useState, useEffect } from "react";
import DeckContentItemDetail from '../components/DeckContentItemDetail';
import DeckContentHeader from '../components/DeckContentHeader'; // Example header for DeckContentItemDetail
import DeckContentNavigation from '../components/DeckContentNavigation'; // Example navigation for DeckContentItemDetail
import DeckContentNavigationRight from '../components/DeckContentNavigationRight'; 
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";

const DeckContentItemLayout = ({ itemId, title, onBack, onPresentationClick, prevIndexNo, openModal, refreshEditContent, deckId, passToApp, handleDeckHeader }) => {
 
  const [itemDetail, setItemDetail] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [indexNo, setIndexNo] = useState(0);
  const [cloneVoices, setcloneVoices] = useState(null);
  const [refCon, setRefCon] = useState(false);
  
  
  useEffect(() => {
    console.log("refreshEditContent", refreshEditContent, itemId, prevIndexNo)
    const fetchItemDetail = async () => {
      console.log("In asyncrefreshEditContent", refreshEditContent, itemId)
      try {
        const userId=localStorage.getItem("userId")
        const cloneVoicesResp = await callAPI(apiUrls.GETPRESENTERS, { userId: userId }, "GET");
        setcloneVoices(cloneVoicesResp.data)
        
        const response = await callAPI(apiUrls.GETPPTDATA, { "fileId":itemId }, 'GET');
        setItemDetail(response.data);
        
        
        setSelectedItemIndex(response.data[0] || {});
        console.log("Final Data ", response.data)
        console.log("prevIndexNoBed", prevIndexNo)
        if (prevIndexNo !== null && prevIndexNo !== undefined &&
          response.data && Array.isArray(response.data) &&
          prevIndexNo >= 0 && prevIndexNo < response.data.length) {
          // Your code here
          console.log("prevIndexNo", prevIndexNo)
          handleItemClick(response.data[prevIndexNo] || {},prevIndexNo) 
      }

      setRefCon(false)
      } catch (error) {
        console.error('Error fetching item detail:', error);
      }
    };

    fetchItemDetail();
  }, [itemId, refreshEditContent, refCon]);

  const handleItemClick = (indexData,index) => {
    console.log('heeeeeiiiiii', index)
    console.log('heeeee', indexData)
    setSelectedItemIndex(indexData);
    setIndexNo(index);
    //passToApp(index)
  };

  const onDelete = (index) => {
    console.log("beforedelete", itemDetail)
    const newItems = itemDetail.filter((_, i) => i !== index);
    console.log("afterdelee", newItems)
    setItemDetail(newItems);

    if (newItems.length === 0) {
      setSelectedItemIndex(null);
      setIndexNo(null);
    } else {
      const newIndex = index >= newItems.length ? newItems.length - 1 : index;
      setSelectedItemIndex(newItems[newIndex]);
      setIndexNo(newIndex);
    }
  };

  const onMove = (updatedItemsWithSequence) => {
    console.log("OnMove", updatedItemsWithSequence, itemDetail)
    const sequenceMap = new Map();
updatedItemsWithSequence.forEach(item => {
    sequenceMap.set(item.imageId, item.sequence);
});

// Update itemDetail with the new sequences
const updatedItemDetail = itemDetail.map(item => {
    const newSequence = sequenceMap.get(item.id);
    if (newSequence !== undefined) {
        return {
            ...item,
            sequence: newSequence
        };
    }
    return item; // Return the item unchanged if no matching sequence is found
});

// Optionally, sort the array based on the new sequence
updatedItemDetail.sort((a, b) => a.sequence - b.sequence);
    setItemDetail(updatedItemDetail);
  };
  const handleSaveContent = (index, updatedContent) => {
    //  // Capture the current state before updating
    // const previousContent = itemDetail[index];

    // // Add an undo action
    // addUndoAction({
    //   action: "updateContent",
    //   undoHandler: () => {
    //     console.log("Undoing content update");
    //     const restoredItemDetail = [...itemDetail];
    //     restoredItemDetail[index] = previousContent;
    //     setItemDetail(restoredItemDetail);
    //     setSelectedItemIndex(previousContent); // Restore the selected index
    //   },
    // });
    // Update itemDetail with the new content for the specific index
    console.log("indexNoSave", index, itemDetail)
    const updatedItemDetail = [...itemDetail];
    updatedItemDetail[index] = updatedContent;
    console.log("indexNoSaveafter", updatedItemDetail)
    setItemDetail(updatedItemDetail);
console.log("updatedContent",updatedContent)
console.log("updatedItemDetail",updatedItemDetail)
    // Update the selected index to re-render the content
    setSelectedItemIndex(updatedContent);
  };

  const onDeckHeaderItemClick = () => {
    onPresentationClick(itemDetail,itemId, indexNo, title, deckId); // Pass the clicked item's data to the parent
  };
  const [checkEditable, setCheckEditable] = useState(false);

  const handleCompleteAction = () => {
    setRefCon(true)
  };
  return (
   
         <div class="container deckEditor">
          
      <DeckContentHeader title={title} onBack={onBack} onDeckHeaderItemClick={onDeckHeaderItemClick} deckId={deckId} itemId={itemId} onCompleteAction={handleCompleteAction}   handleDeckHeader={handleDeckHeader}/> {/* Render specific header for DeckContentItemDetail */}
      <div class="deckDashboard">
      <DeckContentNavigation data= {itemDetail} onItemClick={handleItemClick} checkEditable={checkEditable}  onDelete={onDelete} onMove={onMove}/> {/* Render specific navigation for DeckContentItemDetail */}
    
      <DeckContentItemDetail   selectedItemIndex={selectedItemIndex} indexNo={indexNo} onSaveContent={handleSaveContent} openModal={openModal} title={title} itemId={itemId} /> {/* Render the component itself */}
      <DeckContentNavigationRight  indexNo={indexNo} selectedItemIndex={selectedItemIndex} onSaveContent={handleSaveContent} cloneVoices={cloneVoices} setCheckEditable={setCheckEditable} openModal={openModal}/>
    
      </div>
      </div>
   
  );
};

export default DeckContentItemLayout;
