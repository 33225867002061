import React, { useState, useEffect, useRef } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import { useUndo } from '../contexts/UndoContext';

const DeckContentNavigation = ({ data, onItemClick, checkEditable, onDelete, onMove }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [items, setItems] = useState(data);
  const [wasClicked, setWasClicked] = useState(false);
  const { addUndoAction } = useUndo();

  useEffect(() => {
    setItems(data);
  }, [data]);

  const handleItemClick = (index) => {
   
    const previousSelectedItem = selectedItem; // Store the previous selected item index

    addUndoAction({
      action: "selectSlide",
      undoHandler: () => {
        // Undo: Revert to the previously selected slide
        setSelectedItem(previousSelectedItem);
        onItemClick(items[previousSelectedItem], previousSelectedItem);
      },
      redoHandler: () => {
        // Redo: Select the clicked slide again
        setSelectedItem(index);
        onItemClick(items[index], index);
      },
    });
    
    setSelectedItem(index);
    setWasClicked(true);  
    onItemClick(items[index], index);
  };

  const handleKeyDown = (event) => {
    if (items.length === 0) return;
    if (!checkEditable) {
      let newIndex = selectedItem;
      const previousSelectedItem = selectedItem; // Store the previous index for undo
      switch (event.key) {
        case "ArrowUp":
          addUndoAction({
            action: "selectSlide",
            undoHandler: () => {
              // Undo: Revert to the previously selected slide
              setSelectedItem(previousSelectedItem);
              onItemClick(items[previousSelectedItem], previousSelectedItem);
            },
            redoHandler: () => {
              // Redo: Reapply the new selection
              setSelectedItem(newIndex);
              onItemClick(items[newIndex], newIndex);
            },
          });
          newIndex = (newIndex - 1 + items.length) % items.length;
          break;
        case "ArrowDown":
          addUndoAction({
            action: "selectSlide",
            undoHandler: () => {
              // Undo: Revert to the previously selected slide
              setSelectedItem(previousSelectedItem);
              onItemClick(items[previousSelectedItem], previousSelectedItem);
            },
            redoHandler: () => {
              // Redo: Reapply the new selection
              setSelectedItem(newIndex);
              onItemClick(items[newIndex], newIndex);
            },
          });
          newIndex = (newIndex + 1) % items.length;
          break;
        case "Delete":
        case "Backspace":
          if (wasClicked) {
            handleDelete(selectedItem);
          }
          return;
        default:
          return;
      }

      setSelectedItem(newIndex);
      onItemClick(items[newIndex], newIndex);
    } else {
      return;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".item-container")) {
        setWasClicked(false);
      }
    };
    // Attach event listener when the component mounts
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClickOutside);

    // Detach event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [selectedItem, items, onItemClick, checkEditable, wasClicked]);

  const handleDelete = async (index) => {
    const itemToDelete = items[index];
    const newItems = items.filter((_, i) => i !== index);

  // Add undo and redo actions
  addUndoAction({
    action: "deleteSlide",
    undoHandler: async () => {
      // Undo: Add the deleted item back to its original position
      const revertedItems = [...newItems];
      revertedItems.splice(index, 0, itemToDelete);
      setItems(revertedItems);

      // Restore sequence with the item re-added
      const revertedItemsWithSequence = revertedItems.map((item, i) => ({
        imageId: item.id,
        sequence: i,
      }));
      // Revert API: Re-add the deleted item on the server
      try {
        console.log("Undo delete: Restoring item with ID:", itemToDelete.id);
        await callAPI(apiUrls.RENEWSLIDE, {}, "POST", { imageId: itemToDelete.id });

        console.log("Undo delete: Updating sequence");
        await callAPI(apiUrls.UPDATESLIDESEQ, {}, "POST", { items: revertedItemsWithSequence });
      } catch (error) {
        console.error("Error reverting delete operation:", error);
      }
    },
    redoHandler: async () => {
      // Redo: Re-delete the slide
      const reDeletedItems = [...items];
      reDeletedItems.splice(index, 1);
      setItems(reDeletedItems);

      // Adjust sequence after re-deletion
      const reDeletedItemsWithSequence = reDeletedItems.map((item, i) => ({
        imageId: item.id,
        sequence: i,
      }));

      // Redo API: Remove the item again from the server
      try {
        console.log("Redo delete: Re-deleting item with ID:", itemToDelete.id);
        await callAPI(apiUrls.DELETESLIDE, {}, "POST", { imageId: itemToDelete.id });

        console.log("Redo delete: Updating sequence");
        await callAPI(apiUrls.UPDATESLIDESEQ, {}, "POST", { items: reDeletedItemsWithSequence });
      } catch (error) {
        console.error("Error redoing delete operation:", error);
      }
    },
  });

    setItems(newItems);
    onDelete(index); // Ensure onDelete is defined

    // Adjust sequence of remaining items
    const updatedItemsWithSequence = newItems.map((item, index) => ({
      imageId: item.id,
      sequence: index,
    })) ;

    try {
      console.log("itemToDelete", itemToDelete.id)
      console.log("updatedItemsWithSequence",updatedItemsWithSequence);
      // API call to delete the item
      const response = await callAPI(apiUrls.DELETESLIDE, {}, "POST", { imageId: itemToDelete.id });
      // API call to update the sequences of the remaining items
      const updateResponse = await callAPI(apiUrls.UPDATESLIDESEQ, {}, "POST", {  items: updatedItemsWithSequence  });
    } catch (error) {
      console.error('Error updating items:', error);
    }

    if (newItems.length === 0) {
      setSelectedItem(null);
    } else {
      const newIndex = index >= newItems.length ? newItems.length - 1 : index;
      setSelectedItem(newIndex);
      onItemClick(newItems[newIndex], newIndex);
    }
  };

  const moveItem = async (dragIndex, hoverIndex) => {
    const currentItems = [...items]; //undo

    const updatedItems = [...items];
    const [draggedItem] = updatedItems.splice(dragIndex, 1);
    updatedItems.splice(hoverIndex, 0, draggedItem);


  // Add undo and redo actions
  addUndoAction({
    action: "moveItem",
    undoHandler: async () => {
      // Undo: Revert to the previous state
      setItems(currentItems); 
      setSelectedItem(dragIndex); 
      onItemClick(currentItems[dragIndex], dragIndex); 

      // API call to revert changes
      const revertedItemsWithSequence = currentItems.map((item, index) => ({
        imageId: item.id,
        sequence: index,
      }));
      try {
        console.log("Undo moveItem: Restoring item positions:", revertedItemsWithSequence);
         await callAPI(apiUrls.UPDATESLIDESEQ, {}, "POST", { items: revertedItemsWithSequence });
      } catch (error) {
        console.error("Error reverting move operation:", error);
      }
    },
    redoHandler: async () => {
      // Redo: Apply the move again
      setItems(updatedItems); 
      setSelectedItem(hoverIndex); 
      onItemClick(updatedItems[hoverIndex], hoverIndex); 

      // API call to reapply the changes
      const updatedItemsWithSequence = updatedItems.map((item, index) => ({
        imageId: item.id,
        sequence: index,
      }));
      try {
        console.log("Redo moveItem: Reapplying item positions:", updatedItemsWithSequence);
         await callAPI(apiUrls.UPDATESLIDESEQ, {}, "POST", { items: updatedItemsWithSequence });
      } catch (error) {
        console.error("Error redoing move operation:", error);
      }
    },
  }); 

    setItems(updatedItems);
    setSelectedItem(hoverIndex);
    onItemClick(updatedItems[hoverIndex], hoverIndex); // Update selected item

    // Adjust sequence of items
    const updatedItemsWithSequence = updatedItems.map((item, index) => ({
      imageId: item.id,
      sequence: index,
    }));
    onMove(updatedItemsWithSequence)
    console.log("updatedItemsWithSequence",updatedItemsWithSequence);
    try {
      // API call to update the item positions
   //   await callAPI(apiUrls.UPDATE_ITEMS, { items: updatedItemsWithSequence }, "PUT");
   //const updateResponse = await callAPI(apiUrls.UPDATESLIDESEQ, {}, "POST", {  items: updatedItemsWithSequence  });
    } catch (error) {
      console.error('Error updating items:', error);
    }
  };

  const Item = ({ item, index }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
      accept: "ITEM",
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveItem(draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: "ITEM",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    return (
      <div
        ref={ref}
        key={index}
        // className={selectedItem === index ? "selected" : ""}
        className={`item-container ${selectedItem === index ? "selected" : ""}`}
        onClick={() => handleItemClick(index)}
        style={{
           opacity: isDragging ? 0.5 : 1,
           border: selectedItem === index && wasClicked ? "2px solid white" : selectedItem === index ? "1px solid #D3D3D3" : "none",
          }}
        
      >
        <a href="javascript:void(0);">
          <img src={item.value} alt={`Item ${item.id}`} />
        </a>
        {/* Render other elements based on item properties */}
      </div>
    );
  };

  const renderData = () => {
    return items.map((item, index) => (
      <Item key={index} item={item} index={index} />
    ));
  };

  return (
    <div className="leftCol">
      <div className="decklftSidebar scrollBarC">
        <div className="preLists">
          {items && renderData()}
        </div>
      </div>
    </div>
  );
};

const App = ({ data, onItemClick, checkEditable, onDelete, onMove }) => (
  <DndProvider backend={HTML5Backend}>
    <DeckContentNavigation
      data={data}
      onItemClick={onItemClick}
      checkEditable={checkEditable}
      onDelete={onDelete} 
      onMove={onMove}
    />
  </DndProvider>
);

export default App;
