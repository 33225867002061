import React, { useState, useRef , useEffect}  from 'react';
import { Link } from 'react-router-dom';

const PresenterContentItem = ({ title,  presenterId ,  isVisible, onToggle, openModal, isGlobal}) => {
 //   console.log("imageurl",imageUrl);
  //  console.log("title",title);
  
 const toggleUlVisibility = () => {
   
   onToggle(presenterId);
 };
 const moreOptionNavRef = useRef(null);

 const handleRenClick = (action) => {
   // Call the callback to open the modal in App.js
   console.log("Innnnn")
   openModal(presenterId,title, action);
 };
 const truncatedTitle = title && title.length > 8 && !title.includes(' ') 
 ? `${title.slice(0, 7)}..`
 : title;// title && title.length > 8 ? `${title.slice(0, 8)}...` : title;
  return (
    
    <div class="item">
    
               <div class="avtarIcon">
               {title?title.charAt(0).toUpperCase():""}
               </div>
      
       <div class="titleInfo" title={title}>
          <span>{truncatedTitle}</span>

          { !isGlobal && <div class="moreOptionNav" ref={moreOptionNavRef} onClick={toggleUlVisibility}> 
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
             </svg>
             <ul style={{ display: isVisible ? 'block' : 'none' }}>
                        <li>
                           <a href="javascript:void(0);" class="open_modelgry" data-target="dpRenamePop" onClick={() => handleRenClick('rename')} >
                              <em>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M7.99998 13.3333H14M2 13.3333H3.11636C3.44248 13.3333 3.60554 13.3333 3.75899 13.2965C3.89504 13.2638 4.0251 13.21 4.1444 13.1368C4.27895 13.0544 4.39425 12.9391 4.62486 12.7085L13 4.33333C13.5523 3.78104 13.5523 2.88561 13 2.33333C12.4477 1.78104 11.5523 1.78104 11 2.33333L2.62484 10.7085C2.39424 10.9391 2.27894 11.0544 2.19648 11.1889C2.12338 11.3082 2.0695 11.4383 2.03684 11.5744C2 11.7278 2 11.8909 2 12.217V13.3333Z" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                 </svg>
                              </em>
                              <em>Rename</em>
                           </a>
                        </li>
                        <li>
                           <a href="javascript:void(0);" class="open_modelgry" data-target="dpDeletePop"  onClick={() => handleRenClick('delete')}>
                              <em>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M6 2H10M2 4H14M12.6667 4L12.1991 11.0129C12.129 12.065 12.0939 12.5911 11.8667 12.99C11.6666 13.3412 11.3648 13.6235 11.0011 13.7998C10.588 14 10.0607 14 9.00623 14H6.99377C5.93927 14 5.41202 14 4.99889 13.7998C4.63517 13.6235 4.33339 13.3412 4.13332 12.99C3.90607 12.5911 3.871 12.065 3.80086 11.0129L3.33333 4" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                 </svg>
                              </em>
                              <em>Delete</em>
                           </a>
                        </li>
                     </ul>
          </div> }
       </div>
    </div>
 
  );
};

export default PresenterContentItem;
