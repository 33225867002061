import React from 'react';

const Footer = () => {
  return (
    <div className="footerWrap">
      {/* Footer content */}
      
    </div>
  );
};

export default Footer;
